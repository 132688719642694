import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom, map, startWith } from 'rxjs';
import { PublicService } from 'src/api';
import { Notification } from 'src/api/model/notification';
import * as _ from 'lodash';

// Use this service to store information that should
// be available in the application context for logged in users

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  notifications: Notification[] = [];

  private featureFlags: { [key: string]: any } = {};

  private flagsReady$ = new BehaviorSubject<boolean>(false);

  constructor(
    private publicService: PublicService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          return this.activatedRoute?.children[0].snapshot.paramMap?.get(
            'realm'
          );
        }),
        startWith(this.activatedRoute?.children[0].snapshot.paramMap?.get(
          'realm'
        )),
        distinctUntilChanged(),
      ).subscribe((realm) => {
        this.initializeFeatureFlags(realm);
      })
  }

  private initializeFeatureFlags(realm: string) {
    if (!realm) {
      this.featureFlags = {};
      return;
    }
    this.publicService.getFeatureFlagsForRealm({
      realm
    }).subscribe((flags) => {
      this.featureFlags = flags.flags ?? {};
      this.flagsReady$.next(true);
    });
  }

  public async checkFeatureFlag(feature: string): Promise<boolean> {
    if (!this.flagsReady$.value) {
      await firstValueFrom(this.flagsReady$.pipe(filter(a => !!a)));
    }
    return this.checkFeatureFlagSync(feature);
  }

  public checkFeatureFlagSync(feature: string): boolean {
    const flag = _.get(this.featureFlags, feature);
    if (!_.isNil(flag)) return !!flag;
    return true;
  }
}
