<button class="profile-chip handbook" [matMenuTriggerFor]="supportMenu"
  [matTooltip]="'profile.helpSupport' | translate">
  <mat-icon>question_mark</mat-icon>
</button>
<button class="profile-chip" *ngIf="auth.loggedIn" [matMenuTriggerFor]="loginOptionsMenu">
  <div class="user-icon">
    <mat-icon>person</mat-icon>
  </div>
  <span class="user-name" *ngIf="!auth.isGuest && (auth.userName || auth.userMail)">{{auth.userName ??
    auth.userMail}}</span>
  <div class="separator"></div>
  <mat-icon>menu</mat-icon>
</button>
<button mat-button class="login-button" *ngIf="!auth.loggedIn && !!realm?.length" (click)="showLogin = true">
  <mat-icon>login</mat-icon>
  <span>{{ 'common.login.title' | translate }}</span>
</button>
<mat-menu class="login-menu-global" #supportMenu="matMenu">
  <button mat-menu-item (click)="openHandbook()">
    <mat-icon>menu_book</mat-icon>
    {{ 'profile.handbook' | translate }}
  </button>
  <button mat-menu-item (click)="reportError()" *ngIf="auth.loggedIn">
    <mat-icon>crisis_alert</mat-icon>
    {{ 'profile.reportError' | translate }}
  </button>
  <button mat-menu-item (click)="giveFeedback()" *ngIf="auth.loggedIn">
    <mat-icon>how_to_vote</mat-icon>
    {{ 'profile.giveFeedback' | translate }}
  </button>
  <button mat-menu-item (click)="supportHelp()" *ngIf="auth.loggedIn && !auth.isClient && realm !== ADVD_TENANT_NAME">
    <mat-icon>question_mark</mat-icon>
    {{ 'profile.supportHelp' | translate }}
  </button>
</mat-menu>
<mat-menu #loginOptionsMenu="matMenu" class="login-menu-global">
  <button mat-menu-item (click)="goToProfile()">
    <mat-icon>person</mat-icon>
    {{ 'profile.title' | translate }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="languageMenu">
    <span [ngClass]="[
        'fi',
        'fi-' +
          (translator.currentLang === 'en' ? 'gb' : translator.currentLang)
      ]"></span>
    <span>{{ 'profile.language' | translate}}: {{ translator.currentLang | uppercase }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="supportMenu" class="mobile-only-handbook">
    <mat-icon>question_mark</mat-icon>
    <span>{{ 'profile.helpSupport' | translate}}</span>
  </button>
  <hr />
  <button mat-menu-item (click)="auth.logOut()">
    <mat-icon>logout</mat-icon>
    {{ 'common.button.logout' | translate }}
  </button>
</mat-menu>
<mat-menu #languageMenu="matMenu">
  <ng-container *ngFor="let language of languages">
    <button mat-menu-item [disabled]="language === translator.currentLang" (click)="selectLanguage(language)">
      <span [ngClass]="['fi', 'fi-' + (language === 'en' ? 'gb' : language)]"></span>
      {{ language | uppercase }}
    </button>
  </ng-container>
</mat-menu>

<app-keycloak-overlay mode="login" *ngIf="showLogin" (close)="showLogin = false"
  (login)="showLogin = false"></app-keycloak-overlay>