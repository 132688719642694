/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ExecutionState } from '../model/models';
import { ExecutionStateParticipant } from '../model/models';
import { ExecutionStateParticipantRequest } from '../model/models';
import { ExecutionStateQueryResponse } from '../model/models';
import { ExecutionStateRequest } from '../model/models';
import { ExecutionStateThreadsResponse } from '../model/models';
import { FileInfo } from '../model/models';
import { FilterViewPagination } from '../model/models';
import { Thread } from '../model/models';
import { ThreadPatchRequest } from '../model/models';
import { ThreadPatchResponse } from '../model/models';
import { ThreadRequest } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface AddNewStateRequestParams {
    executionStateRequest: ExecutionStateRequest;
    xAuthToken?: string;
}

export interface AssignParticipantToStateRequestParams {
    /** Id of execution state */
    stateid: string;
    executionStateParticipantRequest: ExecutionStateParticipantRequest;
    xAuthToken?: string;
}

export interface GetExecutionStateRequestParams {
    /** Id of execution state */
    stateid: string;
    xAuthToken?: string;
}

export interface GetStateFilesRequestParams {
    /** Id of execution state */
    stateid: string;
    xAuthToken?: string;
}

export interface GetStateParticipantsRequestParams {
    /** Id of execution state */
    stateid: string;
    xAuthToken?: string;
}

export interface GetThreadInStateRequestParams {
    /** Id of execution state */
    stateid: string;
    /** Id of thread */
    threadid: string;
    xAuthToken?: string;
}

export interface GetThreadsForExecutionStateRequestParams {
    /** Id of execution state */
    stateid: string;
    xAuthToken?: string;
}

export interface PatchThreadsRequestParams {
    /** Id of execution state */
    stateid: string;
    threadPatchRequest: ThreadPatchRequest;
    xAuthToken?: string;
}

export interface QueryExecutionStatesRequestParams {
    filterViewPagination: FilterViewPagination;
    xAuthToken?: string;
}

export interface RemoveExecutionStateRequestParams {
    /** Id of execution state */
    stateid: string;
    xAuthToken?: string;
}

export interface RemoveParticipantRequestParams {
    /** Id of execution state */
    stateid: string;
    /** Id of state participant */
    userid: string;
    xAuthToken?: string;
    /** Source of the user (client/lawyer). Only relevant for DELETE. */
    source?: string;
}

export interface UpdateExecutionStateRequestParams {
    /** Id of execution state */
    stateid: string;
    executionStateRequest: ExecutionStateRequest;
    xAuthToken?: string;
}

export interface UpdateThreadRequestParams {
    /** Id of execution state */
    stateid: string;
    /** Id of thread */
    threadid: string;
    threadRequest: ThreadRequest;
    xAuthToken?: string;
}


@Injectable({
  providedIn: 'root'
})
export class ExecutionService {

    protected basePath = 'https://api.silberfluss.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a new empty state
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addNewState(requestParameters: AddNewStateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ExecutionState>;
    public addNewState(requestParameters: AddNewStateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ExecutionState>>;
    public addNewState(requestParameters: AddNewStateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ExecutionState>>;
    public addNewState(requestParameters: AddNewStateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const executionStateRequest = requestParameters.executionStateRequest;
        if (executionStateRequest === null || executionStateRequest === undefined) {
            throw new Error('Required parameter executionStateRequest was null or undefined when calling addNewState.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ExecutionState>(`${this.configuration.basePath}/state/new`,
            executionStateRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Assign an existing user to this state
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignParticipantToState(requestParameters: AssignParticipantToStateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public assignParticipantToState(requestParameters: AssignParticipantToStateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public assignParticipantToState(requestParameters: AssignParticipantToStateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public assignParticipantToState(requestParameters: AssignParticipantToStateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const stateid = requestParameters.stateid;
        if (stateid === null || stateid === undefined) {
            throw new Error('Required parameter stateid was null or undefined when calling assignParticipantToState.');
        }
        const executionStateParticipantRequest = requestParameters.executionStateParticipantRequest;
        if (executionStateParticipantRequest === null || executionStateParticipantRequest === undefined) {
            throw new Error('Required parameter executionStateParticipantRequest was null or undefined when calling assignParticipantToState.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/state/${encodeURIComponent(String(stateid))}/users`,
            executionStateParticipantRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the execution state info of the specified id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExecutionState(requestParameters: GetExecutionStateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ExecutionState>;
    public getExecutionState(requestParameters: GetExecutionStateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ExecutionState>>;
    public getExecutionState(requestParameters: GetExecutionStateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ExecutionState>>;
    public getExecutionState(requestParameters: GetExecutionStateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const stateid = requestParameters.stateid;
        if (stateid === null || stateid === undefined) {
            throw new Error('Required parameter stateid was null or undefined when calling getExecutionState.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ExecutionState>(`${this.configuration.basePath}/state/${encodeURIComponent(String(stateid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all files and folders for this state
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStateFiles(requestParameters: GetStateFilesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<FileInfo>>;
    public getStateFiles(requestParameters: GetStateFilesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<FileInfo>>>;
    public getStateFiles(requestParameters: GetStateFilesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<FileInfo>>>;
    public getStateFiles(requestParameters: GetStateFilesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const stateid = requestParameters.stateid;
        if (stateid === null || stateid === undefined) {
            throw new Error('Required parameter stateid was null or undefined when calling getStateFiles.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<FileInfo>>(`${this.configuration.basePath}/state/${encodeURIComponent(String(stateid))}/files`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the participants of the state with the specified id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStateParticipants(requestParameters: GetStateParticipantsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ExecutionStateParticipant>>;
    public getStateParticipants(requestParameters: GetStateParticipantsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ExecutionStateParticipant>>>;
    public getStateParticipants(requestParameters: GetStateParticipantsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ExecutionStateParticipant>>>;
    public getStateParticipants(requestParameters: GetStateParticipantsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const stateid = requestParameters.stateid;
        if (stateid === null || stateid === undefined) {
            throw new Error('Required parameter stateid was null or undefined when calling getStateParticipants.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ExecutionStateParticipant>>(`${this.configuration.basePath}/state/${encodeURIComponent(String(stateid))}/users`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get detailed information for the thread with this id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThreadInState(requestParameters: GetThreadInStateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Thread>;
    public getThreadInState(requestParameters: GetThreadInStateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Thread>>;
    public getThreadInState(requestParameters: GetThreadInStateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Thread>>;
    public getThreadInState(requestParameters: GetThreadInStateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const stateid = requestParameters.stateid;
        if (stateid === null || stateid === undefined) {
            throw new Error('Required parameter stateid was null or undefined when calling getThreadInState.');
        }
        const threadid = requestParameters.threadid;
        if (threadid === null || threadid === undefined) {
            throw new Error('Required parameter threadid was null or undefined when calling getThreadInState.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Thread>(`${this.configuration.basePath}/state/${encodeURIComponent(String(stateid))}/threads/${encodeURIComponent(String(threadid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the execution state info of the specified id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThreadsForExecutionState(requestParameters: GetThreadsForExecutionStateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ExecutionStateThreadsResponse>;
    public getThreadsForExecutionState(requestParameters: GetThreadsForExecutionStateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ExecutionStateThreadsResponse>>;
    public getThreadsForExecutionState(requestParameters: GetThreadsForExecutionStateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ExecutionStateThreadsResponse>>;
    public getThreadsForExecutionState(requestParameters: GetThreadsForExecutionStateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const stateid = requestParameters.stateid;
        if (stateid === null || stateid === undefined) {
            throw new Error('Required parameter stateid was null or undefined when calling getThreadsForExecutionState.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ExecutionStateThreadsResponse>(`${this.configuration.basePath}/state/${encodeURIComponent(String(stateid))}/threads`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch locally buffered threads to an existing state
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchThreads(requestParameters: PatchThreadsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ThreadPatchResponse>;
    public patchThreads(requestParameters: PatchThreadsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ThreadPatchResponse>>;
    public patchThreads(requestParameters: PatchThreadsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ThreadPatchResponse>>;
    public patchThreads(requestParameters: PatchThreadsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const stateid = requestParameters.stateid;
        if (stateid === null || stateid === undefined) {
            throw new Error('Required parameter stateid was null or undefined when calling patchThreads.');
        }
        const threadPatchRequest = requestParameters.threadPatchRequest;
        if (threadPatchRequest === null || threadPatchRequest === undefined) {
            throw new Error('Required parameter threadPatchRequest was null or undefined when calling patchThreads.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<ThreadPatchResponse>(`${this.configuration.basePath}/state/${encodeURIComponent(String(stateid))}/threads`,
            threadPatchRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get paginated execution states
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public queryExecutionStates(requestParameters: QueryExecutionStatesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ExecutionStateQueryResponse>;
    public queryExecutionStates(requestParameters: QueryExecutionStatesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ExecutionStateQueryResponse>>;
    public queryExecutionStates(requestParameters: QueryExecutionStatesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ExecutionStateQueryResponse>>;
    public queryExecutionStates(requestParameters: QueryExecutionStatesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const filterViewPagination = requestParameters.filterViewPagination;
        if (filterViewPagination === null || filterViewPagination === undefined) {
            throw new Error('Required parameter filterViewPagination was null or undefined when calling queryExecutionStates.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ExecutionStateQueryResponse>(`${this.configuration.basePath}/states/list`,
            filterViewPagination,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete execution state by id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeExecutionState(requestParameters: RemoveExecutionStateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public removeExecutionState(requestParameters: RemoveExecutionStateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public removeExecutionState(requestParameters: RemoveExecutionStateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public removeExecutionState(requestParameters: RemoveExecutionStateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const stateid = requestParameters.stateid;
        if (stateid === null || stateid === undefined) {
            throw new Error('Required parameter stateid was null or undefined when calling removeExecutionState.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/state/${encodeURIComponent(String(stateid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a participant from this state
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeParticipant(requestParameters: RemoveParticipantRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public removeParticipant(requestParameters: RemoveParticipantRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public removeParticipant(requestParameters: RemoveParticipantRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public removeParticipant(requestParameters: RemoveParticipantRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const stateid = requestParameters.stateid;
        if (stateid === null || stateid === undefined) {
            throw new Error('Required parameter stateid was null or undefined when calling removeParticipant.');
        }
        const userid = requestParameters.userid;
        if (userid === null || userid === undefined) {
            throw new Error('Required parameter userid was null or undefined when calling removeParticipant.');
        }
        const xAuthToken = requestParameters.xAuthToken;
        const source = requestParameters.source;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (source !== undefined && source !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>source, 'source');
        }

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/state/${encodeURIComponent(String(stateid))}/users/${encodeURIComponent(String(userid))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateExecutionState(requestParameters: UpdateExecutionStateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ExecutionState>;
    public updateExecutionState(requestParameters: UpdateExecutionStateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ExecutionState>>;
    public updateExecutionState(requestParameters: UpdateExecutionStateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ExecutionState>>;
    public updateExecutionState(requestParameters: UpdateExecutionStateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const stateid = requestParameters.stateid;
        if (stateid === null || stateid === undefined) {
            throw new Error('Required parameter stateid was null or undefined when calling updateExecutionState.');
        }
        const executionStateRequest = requestParameters.executionStateRequest;
        if (executionStateRequest === null || executionStateRequest === undefined) {
            throw new Error('Required parameter executionStateRequest was null or undefined when calling updateExecutionState.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ExecutionState>(`${this.configuration.basePath}/state/${encodeURIComponent(String(stateid))}`,
            executionStateRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post new thread information (data, messages, node_template changes) for the thread with this id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateThread(requestParameters: UpdateThreadRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Thread>>;
    public updateThread(requestParameters: UpdateThreadRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Thread>>>;
    public updateThread(requestParameters: UpdateThreadRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Thread>>>;
    public updateThread(requestParameters: UpdateThreadRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const stateid = requestParameters.stateid;
        if (stateid === null || stateid === undefined) {
            throw new Error('Required parameter stateid was null or undefined when calling updateThread.');
        }
        const threadid = requestParameters.threadid;
        if (threadid === null || threadid === undefined) {
            throw new Error('Required parameter threadid was null or undefined when calling updateThread.');
        }
        const threadRequest = requestParameters.threadRequest;
        if (threadRequest === null || threadRequest === undefined) {
            throw new Error('Required parameter threadRequest was null or undefined when calling updateThread.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<Thread>>(`${this.configuration.basePath}/state/${encodeURIComponent(String(stateid))}/threads/${encodeURIComponent(String(threadid))}`,
            threadRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
