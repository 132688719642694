<ng-scrollbar>
  <div class="sketch-actions">
    <button mat-button (click)="newDossier()">
      <mat-icon class="button-icon">add</mat-icon>
      <span>{{ "lawyer.dashboard.dossiers.new" | translate }}</span>
    </button>
    <app-search-menu [entries]="queryProcesses.bind(this)" (entrySelected)="addFromProcess($event)"
      *ngIf="permissions.canRead('legal_processes') && commonService.checkFeatureFlagSync('processes.enabled')">
      <button mat-button>
        <mat-icon class="button-icon">account_tree</mat-icon>
        <span>{{ "lawyer.dashboard.dossiers.fromProcess" | translate }}</span>
      </button>
    </app-search-menu>
  </div>
  <app-table [config]="tableConfig" *ngIf="tableConfig" #tableRef></app-table>
</ng-scrollbar>