<ng-container *ngIf="readonly || lockEdit; else editTemplate">
  <div class="doc">
    <app-rich-text-editor [readonly]="true" [(text)]="document.value" [options]="document.options" *ngIf="document"
      #textEditor></app-rich-text-editor>

    <div class="quickaction-buttons">
      <div class="zoom-options">
        <button mat-icon-button (click)="setZoom(zoom + 0.1)">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button (click)="setZoom(zoom - 0.1)">
          <mat-icon>zoom_out</mat-icon>
        </button>
      </div>
      <div class="quickaction" (click)="downloadAsPDF()" *ngIf="auth.loggedIn" [class.loading]="generatingPDF">
        <mat-icon>{{ generatingPDF ? "sync" : "picture_as_pdf" }}</mat-icon>
        <span [innerHTML]="'document.downloadAsPDF' | translate"></span>
      </div>
      <div class="quickaction" (click)="downloadAsDocX()" *ngIf="!readonly">
        <mat-icon>file_download</mat-icon>
        <span [innerHTML]="'document.downloadAsDOCX' | translate"></span>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #editTemplate>
  <div class="split-pane">
    <div class="pane open doc">
      <app-rich-text-editor [readonly]="false" [(text)]="document.value" [dataSource]="dataSource"
        [dataStore]="dataStore" [ownNode]="ownNode" [options]="document.options" [(currentTagNode)]="previewNode"
        [(currentCondition)]="currentCondition" (currentTagNodeChange)="highlightNode.next($event)"
        (textChange)="textChange()" *ngIf="document" #textEditor></app-rich-text-editor>
      <div class="quickaction-buttons">
        <div class="zoom-options">
          <button mat-icon-button (click)="setZoom(zoom + 0.1)">
            <mat-icon>zoom_in</mat-icon>
          </button>
          <button mat-icon-button (click)="setZoom(zoom - 0.1)">
            <mat-icon>zoom_out</mat-icon>
          </button>
        </div>
        <div class="quickaction" *ngIf="auth.loggedIn" [matMenuTriggerFor]="pdfExportModeMenu"
          [class.loading]="generatingPDF">
          <mat-icon>{{ generatingPDF ? "sync" : "picture_as_pdf" }}</mat-icon>
          <span [innerHTML]="'document.downloadAsPDF' | translate"></span>
        </div>
        <mat-menu #pdfExportModeMenu="matMenu">
          <button mat-menu-item (click)="downloadAsPDF('default')"
            [innerHTML]="'document.downloadAsPDF' | translate"></button>
          <button mat-menu-item (click)="downloadAsPDF('DATEV')">
            {{ "document.downloadAsDATEV" | translate }}
          </button>
          <button mat-menu-item (click)="downloadAsPDF('Advolux')">
            {{ "document.downloadAsAdvolux" | translate }}
          </button>
        </mat-menu>
        <div class="quickaction" (click)="downloadAsDocX()">
          <mat-icon>file_download</mat-icon>
          <span [innerHTML]="'document.downloadAsDOCX' | translate"></span>
        </div>
      </div>
      <button mat-icon-button class="fixed-button left"
        (click)="docSettingsOpen = !docSettingsOpen; textEditor.applyOptions()" *ngIf="ownNode">
        <mat-icon>{{ docSettingsOpen ? "close" : "settings" }}</mat-icon>
      </button>
      <div class="document-view-settings" *ngIf="ownNode && docSettingsOpen" [@scaleAnimation]>
        <ng-container *ngFor="let setting of ownNode.node.settings">
          <app-node-setting [logicRef]="setting" [allNodes]="dataSource" [color]="ownNode.node?.style?.backgroundColor"
            [processNode]="ownNode" [context]="nodeContext"></app-node-setting>
        </ng-container>
      </div>
      <button mat-icon-button class="fixed-button right" (click)="rightPaneOpen = !rightPaneOpen">
        <mat-icon>{{
          !rightPaneOpen ? "navigate_before" : "chevron_right"
          }}</mat-icon>
      </button>
    </div>
    <div class="pane" *ngIf="rightPaneOpen" [@sideBarAnimation] [class.disabledLocked]="lockEdit">
      <ng-scrollbar *ngIf="dataSource" (psScrollY)="onListScroll($event)" #sideScrollBar>
        <ng-container *ngFor="let node of dataSource">
          <ng-container *ngIf="node.node?.identifier !== 'FormNode'; else formNode">
            <ng-container *ngTemplateOutlet="singleNodeSetup; context: { node: node }"></ng-container>
          </ng-container>
          <ng-template #formNode>
            <div class="form-node" [class.expanded]="!!node.settingsOpen">
              <button mat-button class="bar" (click)="node.settingsOpen = !node.settingsOpen" [attr.node-id]="node._id">
                <mat-icon>{{ node.node.icon }}</mat-icon>
                <span class="form-name">{{ node.node.config.name.value }}</span>
                <mat-icon>{{
                  !!node.settingsOpen ? "expand_less" : "expand_more"
                  }}</mat-icon>
              </button>
              <ng-container *ngIf="!!node.settingsOpen">
                <ng-container *ngFor="let subnode of tempNodeSettings[node._id]">
                  <ng-container *ngTemplateOutlet="
                      singleNodeSetup;
                      context: { node: subnode }
                    "></ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
      </ng-scrollbar>
      <ng-scrollbar [config]="{ suppressScrollX: true }" *ngIf="!dataSource">
        <ng-content></ng-content>
      </ng-scrollbar>
    </div>
  </div>
</ng-template>

<ng-template #singleNodeSetup let-node="node">
  <div class="node-option" *ngIf="node.node?.settings && node.node?.refIds?.length"
    [style.color]="node.node?.style?.backgroundColor" [class.hasSettings]="node.node?.settings?.length > 1">
    <div class="node-infos" [attr.node-id]="node._id" [attr.refId]="node?.node?.refId ?? null">
      <span class="mode" *ngIf="node.node?.modes">
        <button mat-icon-button [matMenuTriggerFor]="modeSelectMenu"
          [matTooltip]="node.node?.selectedMode?.name | translate">
          <mat-icon *ngIf="node.node?.selectedMode?.icon">{{
            node.node?.selectedMode?.icon
            }}</mat-icon>
          <span *ngIf="!node.node?.selectedMode?.icon">
            {{ node.node?.selectedMode?.name | translate }}
          </span>
        </button>
        <mat-menu #modeSelectMenu="matMenu">
          <button mat-menu-item *ngFor="let mode of node?.node?.modes" (click)="setNodeMode(node, mode)">
            <mat-icon *ngIf="mode.icon">{{ mode.icon }}</mat-icon>
            {{ mode.name | translate }}
          </button>
        </mat-menu>
      </span>
      <span class="names" *ngIf="!node?.node?.selectedMode"
        [matTooltip]="'node.names.' + node.node.typeName | translate">
        <mat-icon>{{
          node?.node?.icon ?? ("node.names." + node.node.typeName | translate)
          }}</mat-icon>
      </span>
      <div class="expand" *ngIf="node.node.settings?.length">
        <app-node-setting [logicRef]="node.node.settings[0]" [allNodes]="dataSource"
          [color]="node.node?.style?.backgroundColor" [processNode]="node" [context]="nodeContext"></app-node-setting>
      </div>
      <div class="refid" *ngIf="node.node.refId" (click)="setRefId(node)" [matTooltip]="'#' + node.node.refId">
        <span>#{{ node.node.refId }}</span>
      </div>
    </div>
    <button mat-icon-button class="settings-button" [class.active]="!!node.settingsOpen"
      (click)="node.settingsOpen = !node.settingsOpen" *ngIf="node.node?.settings?.length > 1">
      <mat-icon>{{ node.settingsOpen ? "close" : "settings" }}</mat-icon>
    </button>
    <div class="node-settings" *ngIf="node.settingsOpen" [style.background]="node.node?.style?.backgroundColor">
      <ng-container *ngFor="let setting of node.node.settings; let i = index">
        <app-node-setting *ngIf="!setting.formOnly && i !== 0" [logicRef]="setting" [allNodes]="dataSource"
          [color]="node.node?.style?.backgroundColor" [processNode]="node" [context]="nodeContext"></app-node-setting>
      </ng-container>
    </div>
  </div>
</ng-template>

<div class="loading-spinner" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>