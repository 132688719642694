import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormattedDataAnswer,
  parseAnswerValue,
  parseUserReference,
  reverseJSONPreview,
} from 'advoprocess';
import { forkJoin, of } from 'rxjs';
import {
  ClientsService,
  CorporateIdentityService,
  ExecutionService,
  ExecutionState,
  FilesService,
  FilterViewPagination,
  Lawyer,
  LawyerCorporateIdentity,
  LawyerService,
  Permission,
  PermissionPolicy,
  Role,
  RolesService,
  User,
  Variable,
} from 'src/api';
import { AuthService } from 'src/app/auth/auth.service';
import { getDossierTableConfig } from 'src/app/views/lawyer/dashboard/pages/dossiers/dossiers.component';
import { FieldChangeEvent } from 'src/app/widgets/data-render-table/data-render-table.component';
import { DialogService } from 'src/app/widgets/dialog/dialog.service';
import {
  TableComponent,
  TableConfig,
} from 'src/app/widgets/table/table.component';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap } from 'rxjs/operators';
import { clientsFilters } from 'src/app/views/process/filters/clients-filters';
import { lawyersFilters } from 'src/app/views/process/filters/lawyers-filters';
import {
  isFilterCriterium,
  isParseAssignedUser,
  mergeAvailableFilters,
} from '../../../../../../common/helpers';
import {
  PermissionsService,
  ValidationResult,
} from 'src/app/auth/permissions.service';
import { groupsFilter } from 'src/app/views/process/filters/groups-filters';
import { Location } from '@angular/common';
import { SettingsService } from '../../../../../../common/settings.service';
import { uploadProfilePicture } from './helpers';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/common/common.service';

export interface EditUserDefinition {
  entity: 'client' | 'lawyer' | 'role';
  uuid: string;
}

type CombinedLawyerUser = (Lawyer | User) & {
  entity: 'lawyer' | 'client';
};

export interface UserInfoForm {
  uuid: FormControl<string | undefined>;
  roleName: FormControl<string | undefined>;
  icon: FormControl<string | undefined>;
  description: FormControl<string | undefined>;
  firstName: FormControl<string | undefined>;
  lastName: FormControl<string | undefined>;
  mail: FormControl<string | undefined>;
  createdAt: FormControl<string | undefined>;
  guest: FormControl<boolean>;
  canAuth: FormControl<boolean>;
  preferred_locale: FormControl<string>;
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnChanges {
  @Input() user?: EditUserDefinition = undefined;
  rawUser?: Lawyer | User | Role = undefined;

  variableValidation: boolean | string = true;
  editedFieldsKeys: string[] = [];

  @ViewChild('membersTable') membersTableRef: TableComponent;
  @ViewChild('rolesTable') rolesTableRef: TableComponent;

  activeTab: 'data' | 'dossiers' | 'permissions' | 'members' = 'data';
  formattedDataAnswers: (FormattedDataAnswer & { variableId: string })[];

  somethingChanged = false;

  allRoles: Role[] = [];
  allPermissions: Permission[] = [];

  dossierTableConfig: TableConfig<ExecutionState>;

  canWriteUser: boolean = false;

  API_URL = environment.API_URL;

  userInfoForm = new FormGroup<UserInfoForm>({
    uuid: new FormControl(undefined),
    roleName: new FormControl(undefined),
    icon: new FormControl(undefined),
    description: new FormControl(undefined),
    firstName: new FormControl(undefined),
    lastName: new FormControl(undefined),
    mail: new FormControl(undefined),
    createdAt: new FormControl(undefined),
    guest: new FormControl(false),
    canAuth: new FormControl(false),
    preferred_locale: new FormControl(undefined)
  });

  variables: Variable[] = [];
  oldVariables: Variable[] = [];

  corporateIdentity: LawyerCorporateIdentity = undefined;
  changedCIFields = new Set<string>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.initData();
    }
  }

  get isClient() {
    return this.auth.isClient;
  }

  get clientPortalEnabled() {
    let enabled = false;
    try {
      enabled =
        this.settings.getSettingSync('feature.clientPortal.disable', 'global')
          ?.value !== 'true';
    } catch { }
    return enabled && this.commonService.checkFeatureFlagSync('clients.login');
  }

  get isSelf() {
    return (
      this.auth.userId === this.user.uuid &&
      (this.user.entity === 'client') === this.auth.isClient
    );
  }

  private initData() {
    if (this.user.entity === 'client') {
      this.activeTab = 'data';
      this.initClientData();
    } else if (this.user.entity === 'lawyer') {
      this.activeTab = 'data';
      this.initLawyerData();
    } else {
      this.activeTab = 'members';
      this.initGroupData();
    }
  }

  private initTableConfig() {
    this.dossierTableConfig = getDossierTableConfig(
      this.states,
      this.router,
      this.route,
      this.dialog,
      this.snackBar,
      this.translator,
      () => [
        {
          operand:
            this.user.entity === 'lawyer'
              ? 'lawyer_roles.lawyer.id'
              : 'client_roles.client.id',
          operator: 'eq',
          value: this.userInfoForm.get('uuid')?.value,
        },
      ]
    );
  }

  initializeFormattedData(): {
    name: string;
    type: string;
    value: any;
  }[] {
    const validated = this.validateCurrentModel();
    this.canWriteUser = !!validated.success;
    if (!this.variables) return;
    const buffer: (FormattedDataAnswer & { variableId: string })[] = [];
    for (const element of this.variables) {
      buffer.push(this.varToFormattedAnswer(element));
    }
    this.formattedDataAnswers = buffer;

    this.setNew(this.formattedDataAnswers);
  }

  private varToFormattedAnswer(
    element: Variable
  ): FormattedDataAnswer & { variableId: string } {
    return {
      name: element.name,
      value: parseAnswerValue(
        element.value,
        element.type ?? '',
        this.auth.jwtToken$.value
      ),
      meta: element.meta,
      type: element.type ?? '',
      rawValue: element.value,
      variableId: element.id,
    };
  }

  private setNew(answers: FormattedDataAnswer[], path = []) {
    for (const answer of answers) {
      if (this.editedFieldsKeys.includes(path.concat(answer.name).join('/'))) {
        (answer as any).new = true;
      }
      if (answer.type === 'subdata') {
        this.setNew((answer.value as any).data, path.concat(answer.name));
      }
    }
  }

  constructor(
    private clients: ClientsService,
    private lawyers: LawyerService,
    private roles: RolesService,
    private states: ExecutionService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: DialogService,
    private snackBar: MatSnackBar,
    public auth: AuthService,
    private translator: TranslateService,
    private activatedRoute: ActivatedRoute,
    private permissions: PermissionsService,
    private location: Location,
    private settings: SettingsService,
    private filesService: FilesService,
    private ciService: CorporateIdentityService,
    public commonService: CommonService
  ) {
    this.userInfoForm.get('uuid').disable();
    this.userInfoForm.get('createdAt').disable();
  }

  get initials(): string {
    if (this.user.entity === 'role')
      return this.userInfoForm.get('roleName').value?.slice(0, 2);
    if (
      !this.userInfoForm?.get('firstName')?.value ||
      !this.userInfoForm?.get('lastName')?.value
    ) {
      return this.userInfoForm?.get('mail')?.value?.slice(0, 2);
    }
    return `${this.userInfoForm
      ?.get('firstName')
      ?.value?.[0].toUpperCase()}${this.userInfoForm
        ?.get('lastName')
        ?.value?.[0].toUpperCase()}`;
  }

  get fullName(): string | undefined {
    if (this.user.entity === 'role')
      return this.userInfoForm.get('roleName').value;
    return [
      this.userInfoForm?.get('firstName')?.value,
      this.userInfoForm?.get('lastName')?.value,
    ]
      .filter((n) => !!n)
      .join(' ');
  }

  fieldChange(event: FieldChangeEvent) {
    this.somethingChanged = true;
    let point = this.variables.find((d) => d.name === event.oldFieldName);
    if (!point) {
      point = {
        name: event.oldFieldName,
        type: 'string',
        value: event.oldValue,
        id: undefined,
        meta: event.meta ?? undefined,
      };
      this.variables.push(point);
    }
    point.name = event.newFieldName;
    if (!point.name) {
      this.variables.splice(this.variables.indexOf(point), 1);
      this.initializeFormattedData();
      return;
    }
    let parsed = event.newValue;
    (() => {
      point.meta = event.meta ?? (undefined as any);
      const questionType = (point.meta as any)?.questionType as
        | string
        | undefined;
      if (
        (!questionType || questionType === 'number') &&
        parseFloat(parsed) &&
        !Number.isNaN(parseFloat(parsed))
      ) {
        point.value = parseFloat(parsed);
        point.type = 'number';
        return;
      }
      if (_.isObject(parsed)) {
        if (parsed['type'] === 'json' && parsed['data']) {
          point.value = reverseJSONPreview((parsed as any).data);
          point.value = point.value['Object'];
        } else {
          point.value = parsed;
        }
        point.type = 'object';
        return;
      }
      try {
        parsed = JSON.parse(parsed);
        if (_.isObject(parsed)) {
          if (parsed['type'] === 'json' && parsed['data']) {
            point.value = reverseJSONPreview((parsed as any).data);
          } else {
            point.value = parsed;
          }
          point.type = 'object';
          return;
        }
      } catch { }
      point.value = event.newValue;
      point.type = 'string';
      return;
    })();
    if (event.newFieldName) {
      this.editedFieldsKeys.push([...event.path, event.newFieldName].join('/'));
    }
    const newEntry = this.varToFormattedAnswer(point);
    const existingEntryIndex = this.formattedDataAnswers.findIndex(
      (a) => a.variableId === point.id
    );
    if (existingEntryIndex !== -1) {
      this.formattedDataAnswers.splice(existingEntryIndex, 1, newEntry);
    } else {
      this.formattedDataAnswers.push(newEntry);
    }
    this.setNew(this.formattedDataAnswers);
  }

  save() {
    const diff = this.buildVariableDiff();
    this.editedFieldsKeys = [];
    switch (this.user.entity) {
      case 'client':
        this.saveClient(diff);
        break;
      case 'lawyer':
        this.saveLawyer(diff);
        break;
      case 'role':
        this.saveGroup();
        break;
      default:
        break;
    }
  }

  private buildVariableDiff() {
    const added = this.variables.filter(
      (v) => !this.oldVariables.some((v2) => v2.id === v.id)
    );
    const removed = this.oldVariables
      .filter((v) => !this.variables.some((v2) => v2.id === v.id))
      .map((v) => v.id);
    const changed = this.variables
      .map((v) => [v, this.oldVariables.find((v2) => v2.id === v.id)])
      .filter(([_, n]) => !!n)
      .filter(
        ([v1, v2]) =>
          JSON.stringify(v1.value) !== JSON.stringify(v2.value) ||
          v1.name !== v2.name
      )
      .map(([v1, _]) => v1);
    return { added, removed, changed };
  }

  async deleteUser() {
    switch (this.user.entity) {
      case 'client':
        await this.deleteClient();
        break;
      case 'lawyer':
        await this.deleteLawyer();
        break;
      case 'role':
        this.deleteGroup();
        break;
    }
  }

  async requestPasswordReset() {
    switch (this.user.entity) {
      case 'client':
        this.clients
          .requestClientPasswordChange({
            userid: this.user.uuid,
          })
          .subscribe(() => {
            this.snackBar.open(
              this.translator.instant(
                'lawyer.dashboard.clients.requestedPasswordChange'
              )
            );
          });
        break;
      case 'lawyer':
        this.lawyers
          .requestLawyerPasswordChange({
            userid: this.user.uuid,
          })
          .subscribe(() => {
            this.snackBar.open(
              this.translator.instant(
                'lawyer.dashboard.clients.requestedPasswordChange'
              )
            );
          });
        break;
    }
  }

  async createSystemAccess() {
    if (this.user.entity !== 'client') return;
    this.clients
      .updateUser({
        user: {
          can_auth: true,
        },
        userid: this.user.uuid,
      })
      .subscribe(() => {
        this.userInfoForm.get('canAuth').setValue(true);
        this.userInfoForm.get('guest').setValue(false);
        this.requestPasswordReset();
      });
  }

  async changePassword() {
    let password: string = undefined;
    while (!password) {
      const data = await this.dialog
        .edit('lawyer.userSettings.changePassword', {
          password: {
            value: '',
            type: 'password',
            name: 'common.login.password',
            required: true,
          },
          repeatPassword: {
            value: '',
            type: 'password',
            name: 'common.login.repeatPassword',
            required: true,
          },
        })
        .catch(() => {
          return;
        });
      if (!data) return;
      if (data.password.value !== data.repeatPassword.value) {
        this.snackBar.open(
          this.translator.instant('common.login.error.noPasswordMatch')
        );
      } else {
        password = data.password.value;
      }
    }
    switch (this.user.entity) {
      case 'client':
        await this.updateClientPassword(password);
        break;
      case 'lawyer':
        await this.updateLawyerPassword(password);
        break;
      default:
        break;
    }
  }

  private initPermissionData() {
    this.roles
      .listRoles({
        filterViewPagination: {
          view: {
            displayed_columns: [
              {
                display_name: 'name',
                internal_name: 'name',
              },
              {
                display_name: 'id',
                internal_name: 'id',
              },
            ],
            hidden_columns: [],
          },
          filter: [
            {
              operator: 'eq',
              operand:
                this.user.entity === 'client' ? 'clients.id' : 'lawyers.id',
              value: this.user.uuid,
            },
          ],
          pagination: {
            page: 1,
            rows_per_page: 100,
          },
        },
      })
      .subscribe((resp) => {
        this.allRoles = resp.roles;
      });
    if (this.user.entity === 'lawyer') {
      this.lawyers
        .getLawyerPermissions({
          userid: this.user.uuid,
        })
        .subscribe((perm) => {
          this.allPermissions = perm;
        });
    }
  }

  /** Stuff for clients */

  private initClientData() {
    this.initTableConfig();
    this.rolesTableConfig = this.getGroupsTableConfig(this.user.uuid, 'client');
    this.initPermissionData();
    forkJoin([
      this.clients.getUserById({
        userid: this.user.uuid,
      }),
      this.clients.getUserVariables({
        userid: this.user.uuid,
      }),
    ]).subscribe(([usrinfo, variables]) => {
      this.variables = variables;
      this.oldVariables = _.cloneDeep(variables);
      this.userInfoForm.patchValue({
        firstName: usrinfo.first_name,
        lastName: usrinfo.last_name,
        uuid: usrinfo.id,
        mail: usrinfo.mail,
        createdAt: usrinfo.created_at,
        guest: usrinfo.guest ?? false,
        canAuth: usrinfo.can_auth ?? false,
        preferred_locale: usrinfo.preferred_locale ?? undefined
      });
      this.rawUser = usrinfo;
      this.initializeFormattedData();
    });
  }

  private entityToUser() {
    switch (this.user.entity) {
      case 'client':
      case 'lawyer':
        return {
          first_name: this.userInfoForm.get('firstName')?.value ?? '',
          last_name: this.userInfoForm.get('lastName')?.value ?? '',
          mail: this.userInfoForm.get('mail')?.value,
          preferred_locale: this.userInfoForm.get('preferred_locale')?.value ?? undefined
        };
      case 'role':
        return {
          description: this.userInfoForm.get('description')?.value ?? '',
          icon: this.userInfoForm.get('icon')?.value ?? '',
          name: this.userInfoForm.get('roleName')?.value,
        };
      default:
        return {};
    }
  }

  private saveClient(diff: {
    added: Variable[];
    removed: string[];
    changed: Variable[];
  }) {
    forkJoin([
      diff?.removed?.length
        ? this.clients.removeUserVariables({
          userid: this.user.uuid,
          requestBody: diff.removed,
        })
        : of([]),
      diff?.changed?.length
        ? this.clients.addUserVariables({
          userid: this.user.uuid,
          variable: diff.changed,
        })
        : of([]),
      diff?.added?.length
        ? this.clients.addUserVariables({
          userid: this.user.uuid,
          variable: diff.added,
        })
        : of([]),
      this.clients.updateUser({
        userid: this.user.uuid,
        user: this.entityToUser(),
      }),
    ])
      .pipe(
        switchMap(() =>
          this.clients.getUserVariables({
            userid: this.user.uuid,
          })
        )
      )
      .subscribe((variables) => {
        this.variables = variables;
        this.oldVariables = _.cloneDeep(variables);
        this.somethingChanged = false;
        this.initializeFormattedData();
        this.snackBar.open(
          this.translator.instant('lawyer.dashboard.clients.updatedSuccessful')
        );
      });
  }

  private updateClientPassword(password: string) {
    this.clients
      .updateUser({
        userid: this.user.uuid,
        user: {
          auth_token: password,
        },
      })
      .subscribe(() => {
        this.snackBar.open(
          this.translator.instant('common.message.changedPasswordSuccessfully')
        );
      });
  }

  private async deleteClient() {
    if (
      await this.dialog.confirm({
        text: 'lawyer.dashboard.clients.confirmDelete',
      })
    ) {
      this.clients
        .deleteUser({
          userid: this.user.uuid,
        })
        .subscribe(
          () => {
            this.snackBar.open(
              this.translator.instant('lawyer.dashboard.clients.deletedUser')
            );
            this.location.back();
          },
          () => {
            this.snackBar.open(
              this.translator.instant('common.error.genericDelete'),
              '',
              { duration: 3000 }
            );
          }
        );
    }
  }

  /** Stuff for lawyers */
  private initLawyerData() {
    this.initTableConfig();
    this.initPermissionData();
    this.rolesTableConfig = this.getGroupsTableConfig(this.user.uuid, 'lawyer');
    forkJoin([
      this.lawyers.getLawyerById({
        lawyerid: this.user.uuid,
      }),
      this.lawyers.getLawyerVariables({
        userid: this.user.uuid,
      }),
      this.ciService.getLawyerCorporateIdentity({
        lawyerid: this.user.uuid,
      }),
    ]).subscribe(([usrinfo, variables, corporateIdentity]) => {
      this.variables = variables;
      this.corporateIdentity = corporateIdentity;
      this.oldVariables = _.cloneDeep(variables);
      this.userInfoForm.patchValue({
        firstName: usrinfo.first_name,
        lastName: usrinfo.last_name,
        uuid: usrinfo.id,
        mail: usrinfo.mail,
      });
      this.rawUser = usrinfo;
      this.initializeFormattedData();
    });
  }

  private saveLawyer(diff: {
    added: Variable[];
    removed: string[];
    changed: Variable[];
  }) {
    forkJoin([
      diff?.removed?.length
        ? this.lawyers.removeLawyerVariables({
          userid: this.user.uuid,
          requestBody: diff.removed,
        })
        : of([]),
      diff?.changed?.length
        ? this.lawyers.addLawyerVariables({
          userid: this.user.uuid,
          variable: diff.changed,
        })
        : of([]),
      diff?.added?.length
        ? this.lawyers.addLawyerVariables({
          userid: this.user.uuid,
          variable: diff.added,
        })
        : of([]),
      this.lawyers.updateLawyer({
        lawyerid: this.user.uuid,
        lawyer: this.entityToUser(),
      }),
      ...(this.changedCIFields.size
        ? [
          this.ciService.setLawyerCorporateIdentity({
            lawyerid: this.user.uuid,
            lawyerCorporateIdentity: _.fromPairs(
              Array.from(this.changedCIFields.values()).map((n) => [
                n,
                this.corporateIdentity[n],
              ])
            ),
          }),
        ]
        : []),
    ])
      .pipe(
        switchMap(() =>
          this.lawyers.getLawyerVariables({
            userid: this.user.uuid,
          })
        )
      )
      .subscribe((variables) => {
        this.variables = variables;
        this.oldVariables = _.cloneDeep(variables);
        this.somethingChanged = false;
        this.changedCIFields.clear();
        this.initializeFormattedData();
        this.snackBar.open(
          this.translator.instant('lawyer.dashboard.clients.updatedSuccessful')
        );
      });
  }

  private async deleteLawyer() {
    if (
      await this.dialog.confirm({
        text: 'lawyer.dashboard.clients.confirmDelete',
      })
    ) {
      this.lawyers
        .deleteLawyer({
          lawyerid: this.user.uuid,
        })
        .subscribe(
          () => {
            this.location.back();
          },
          () => {
            this.snackBar.open(
              this.translator.instant('common.error.genericDelete'),
              '',
              { duration: 3000 }
            );
          }
        );
    }
  }

  private updateLawyerPassword(password: string) {
    this.clients
      .updateUser({
        userid: this.user.uuid,
        user: {
          auth_token: password,
        },
      })
      .subscribe(() => {
        this.snackBar.open(
          this.translator.instant('common.message.changedPasswordSuccessfully')
        );
      });
  }

  /** Stuff for roles */

  membersTableConfig: TableConfig<Lawyer | User> | undefined = undefined;
  rolesTableConfig: TableConfig<Role> | undefined = undefined;

  private initGroupData() {
    this.membersTableConfig = this.getMembersTableConfig(this.user.uuid);
    this.roles
      .getRoleById({
        roleid: this.user.uuid,
      })
      .subscribe((roleinfo) => {
        this.variables = [];
        this.oldVariables = [];
        this.userInfoForm.patchValue({
          uuid: roleinfo.id,
          roleName: roleinfo.name,
          description: roleinfo.description,
          icon: roleinfo.icon,
        });
        this.rawUser = roleinfo;
        this.initializeFormattedData();
      });
  }

  private saveGroup() {
    this.roles
      .updateRole({
        roleid: this.user.uuid,
        role: this.entityToUser(),
      })
      .subscribe(() => {
        this.somethingChanged = false;
        this.initializeFormattedData();
        this.snackBar.open(
          this.translator.instant('lawyer.dashboard.clients.updatedSuccessful')
        );
      });
  }

  private async deleteGroup() {
    if (
      await this.dialog.confirm({
        text: 'lawyer.dashboard.clients.confirmDelete',
      })
    ) {
      this.roles
        .deleteRole({
          roleid: this.user.uuid,
        })
        .subscribe(
          () => {
            this.location.back();
          },
          () => {
            this.snackBar.open(
              this.translator.instant('common.error.genericDelete'),
              '',
              { duration: 3000 }
            );
          }
        );
    }
  }

  private getGroupsTableConfig(
    userId: string,
    entity: 'client' | 'lawyer'
  ): TableConfig<Role> {
    return {
      id: 'internal-groups',
      fetch: (params: FilterViewPagination) => {
        params.filter = _.cloneDeep(params.filter);
        params.filter.push({
          operand: entity === 'client' ? 'clients.id' : 'lawyers.id',
          operator: 'eq',
          value: userId,
        });
        return this.roles
          .listRoles({
            filterViewPagination: params,
          })
          .pipe(
            map((resp) => {
              return {
                data: resp.roles,
                total_entries: resp.total_entries,
                view: params.view,
              };
            })
          );
      },
      view: {
        displayed_columns: [
          {
            display_name: this.translator.instant('role.filter.label.name'),
            internal_name: 'name',
            icon: (element) => {
              return element.icon;
            },
          },
          {
            display_name: this.translator.instant(
              'role.filter.label.description'
            ),
            internal_name: 'description',
          },
        ],
        hidden_columns: [
          {
            display_name: 'id',
            internal_name: 'id',
          },
          {
            display_name: 'icon',
            internal_name: 'icon',
          },
        ],
      },
      availableFilters: groupsFilter,
      actions: [
        {
          handler: (element, dataSource) => {
            this.roles
              .removeUserFromRole({
                entity: entity,
                roleid: element.id,
                userid: this.user.uuid,
              })
              .subscribe(() => {
                this.rolesTableRef.dataSourceObj.update();
              });
          },
          id: 'unlink',
          icon: 'link_off',
          name: 'lawyer.dashboard.groups.removeMember',
        },
      ],
    };
  }
  private getMembersTableConfig(
    roleId: string
  ): TableConfig<CombinedLawyerUser> {
    return {
      id: 'internal-group-members',
      fetch: (params: FilterViewPagination) => {
        params.filter = _.cloneDeep(params.filter);
        params.filter.push({
          operand: 'roles.id',
          operator: 'eq',
          value: roleId,
        });
        return forkJoin([
          this.lawyers.listLawyers({
            filterViewPagination: params,
          }),
          this.clients.listUsers({
            filterViewPagination: params,
          }),
        ]).pipe(
          map(([lawyers, clients]) => {
            return {
              view: params.view,
              total_entries: lawyers.total_entries + clients.total_entries,
              data: lawyers.users
                .filter(
                  () =>
                    !params.filter.some(
                      (f) =>
                        isFilterCriterium(f) &&
                        f.operand === 'entity' &&
                        f.value !== 'lawyer'
                    )
                )
                .map((u) => {
                  const temp = { ...u, entity: 'lawyer' };
                  temp[
                    this.translator.instant('lawyer.dashboard.groups.entity')
                  ] = this.translator.instant(
                    'lawyer.dashboard.clients.internalContacts'
                  );
                  return temp;
                })
                .concat(
                  clients.users
                    .filter(
                      () =>
                        !params.filter.some(
                          (f) =>
                            isFilterCriterium(f) &&
                            f.operand === 'entity' &&
                            f.value !== 'client'
                        )
                    )
                    .map((u) => {
                      const temp = { ...u, entity: 'client' };
                      temp[
                        this.translator.instant(
                          'lawyer.dashboard.groups.entity'
                        )
                      ] = this.translator.instant(
                        'lawyer.dashboard.clients.externalContacts'
                      );
                      return temp;
                    })
                ) as CombinedLawyerUser[],
            };
          })
        );
      },
      view: {
        displayed_columns: [
          {
            display_name: this.translator.instant('client.filter.label.mail'),
            internal_name: 'mail',
          },
          {
            display_name: this.translator.instant('client.filter.label.name'),
            internal_name: 'full_name',
          },
        ],
        hidden_columns: [
          {
            display_name: 'id',
            internal_name: 'id',
          },
        ],
      },
      availableFilters: mergeAvailableFilters(
        clientsFilters,
        lawyersFilters
      ).concat([
        {
          internal_name: 'entity',
          label: 'lawyer.dashboard.groups.entity',
          local: true,
          id: 'role_entity',
          options: [
            {
              label: 'lawyer.dashboard.clients.externalContacts',
              value: 'client',
            },
            {
              label: 'lawyer.dashboard.clients.internalContacts',
              value: 'lawyer',
            },
          ],
        },
      ]),
      actions: [
        {
          handler: (element, dataSource) => {
            this.roles
              .removeUserFromRole({
                entity: element.entity,
                roleid: this.user.uuid,
                userid: element.id,
              })
              .subscribe(() => {
                this.membersTableRef.dataSourceObj.update();
              });
          },
          id: 'unlink',
          icon: 'link_off',
          name: 'lawyer.dashboard.groups.removeMember',
        },
      ],
    };
  }

  addMember(event: string) {
    const parsed = parseUserReference(event);
    if (isParseAssignedUser(parsed)) {
      this.roles
        .assignUserToRole({
          entity: parsed.source,
          roleid: this.user.uuid,
          userid: parsed.uuid,
        })
        .subscribe(async () => {
          this.membersTableRef.dataSourceObj.update();
        });
    }
  }

  private validateCurrentModel(): ValidationResult {
    const model: PermissionPolicy.ModelEnum =
      this.user.entity === 'client'
        ? PermissionPolicy.ModelEnum.Clients
        : this.user.entity === 'lawyer'
          ? PermissionPolicy.ModelEnum.Lawyers
          : PermissionPolicy.ModelEnum.Roles;
    const validated = this.permissions.validate(this.getTestModel(), model, [
      'variables',
      'permissions',
      'roles',
    ]);
    return validated;
  }

  validateVariables(values: FormattedDataAnswer[]): boolean | string {
    if (!this.rawUser) return true;
    const validated = this.validateCurrentModel();
    if (validated.success) return true;
    else {
      return this.translator.instant('common.error.inputWithExplanation', {
        explanation: this.permissions.humanReadableError(
          validated.error,
          this.user.entity === 'client'
            ? clientsFilters
            : this.user.entity === 'lawyer'
              ? lawyersFilters
              : groupsFilter
        ),
      });
    }
  }

  private getTestModel() {
    return {
      ...this.rawUser,
      ...this.entityToUser(),
      variables:
        this.formattedDataAnswers?.map((v) => ({
          name: v.name,
          type: v.type,
          value: v.value,
        })) ?? [],
      permissions: this.allPermissions,
      roles: this.allRoles,
    };
  }

  getVariableOptions(variable: FormattedDataAnswer) {
    if (!this.user) return [];
    const model: PermissionPolicy.ModelEnum =
      this.user.entity === 'client'
        ? PermissionPolicy.ModelEnum.Clients
        : this.user.entity === 'lawyer'
          ? PermissionPolicy.ModelEnum.Lawyers
          : PermissionPolicy.ModelEnum.Roles;
    return this.permissions.getPossibleValuesFor(
      model,
      this.getTestModel(),
      `variables(name eq ${variable.name}).value`
    );
  }

  canRead(model: PermissionPolicy.ModelEnum): boolean {
    return this.permissions.canRead(model);
  }

  back() {
    this.location.back();
  }

  editUserIcon() {
    if (this.user?.entity === 'role') {
      this.dialog
        .edit(this.translator.instant('common.label.icon'), {
          icon: {
            value: this.userInfoForm?.get('icon')?.value ?? '',
            name: 'common.label.icon',
            type: 'icon',
          },
        })
        .then((result) => {
          if (!result?.icon?.value) return;
          this.userInfoForm.get('icon').setValue(result.icon.value);
          this.somethingChanged = true;
        });
    } else if (this.user?.entity === 'lawyer') {
      uploadProfilePicture(
        this.filesService,
        this.ciService,
        this.user.uuid
      ).then(() => {
        this.ciService
          .getLawyerCorporateIdentity({
            lawyerid: this.user.uuid,
          })
          .subscribe((ci) => {
            this.corporateIdentity = ci;
          });
      });
    }
  }

  get realm(): string {
    return this.activatedRoute.snapshot.paramMap.get('realm');
  }

  updateCI(newValue: string, targetName: string) {
    this.corporateIdentity[targetName] = newValue;
    this.somethingChanged = true;
    this.changedCIFields.add(targetName);
  }
}
