import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  ProcessParser,
  __pendingFileUploadBuffer,
  acceptedFiles,
  parseFileReference,
  removePendingFile,
  toFileReference,
  uploadFile,
} from 'advoprocess';
import { FilesService } from 'src/api';
import { AuthService } from 'src/app/auth/auth.service';
import * as _ from 'lodash';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { TranslateService } from '@ngx-translate/core';

export interface FileUploadEntry {
  file: File;
  preview?: SafeResourceUrl;
}

const FORMAT_TRANSLATION_MAP = {
  'image\\/.*': 'images',
  'application\\/pdf': 'pdfFiles',
  'application\\/msword': 'word',
};

@Component({
  selector: 'app-file-upload-input',
  template: `
    <div class="answer-title" *ngIf="inline && message?.content">
      <mat-icon>info</mat-icon>
      <p
        [innerHTML]="message.content | sanitizeHtml"
        [class.required]="!!required"
      ></p>
    </div>
    <ngx-dropzone
      (change)="onSelectFile($event)"
      [multiple]="message.responseRequest.params.multipleDocuments"
      [maxFileSize]="10000000"
      [accept]="acceptedFileTypes"
    >
      <ngx-dropzone-label>
        <mat-icon>file_upload</mat-icon>
        <span class="hint">{{
          'client.process.fileUpload.hint' | translate
        }}</span>
        <div class="more-info">
          <span
            >{{ 'client.process.fileUpload.allowedFormats' | translate }}
            {{
              humanReadableFileFormats(message.responseRequest.params.format)
            }}</span
          >
          <span>{{ 'client.process.fileUpload.maxFileSize' | translate }}</span>
        </div>
      </ngx-dropzone-label>
      <ngx-dropzone-preview
        *ngFor="let f of files; let i = index"
        [removable]="true"
        (removed)="onFileRemove(i)"
      >
        <ngx-dropzone-label [class.isImage]="!!f.preview">
          <div class="preview-image" *ngIf="!!f.preview">
            <img [src]="f.preview" />
          </div>
          <div class="filename-preview" *ngIf="!f.preview">
            <mat-icon>attachment</mat-icon><span>{{ f.file.name }}</span>
          </div>
        </ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ngx-dropzone>
    <button
      mat-raised-button
      color="primary"
      style="margin-top: 1rem"
      *ngIf="
        !inline &&
        (!message?.responseRequest?.params?.options ||
          message?.responseRequest.params?.options?.length === 0)
      "
      (click)="saveFile()"
      [disabled]="files.length <= 0"
    >
      {{ 'common.button.continue' | translate }}
    </button>
  `,
})
export class FileUploadInputComponent implements OnInit {
  @Input() message: any;
  @Input() value: any = [];
  @Input() inline: boolean;
  @Input() required: boolean;
  @Input() parser: ProcessParser;
  @Input() stateId?: string;

  @Output() answered = new EventEmitter<any>();
  @Output() changed = new EventEmitter<any>();
  @Output() hasError = new EventEmitter<boolean>();

  files: FileUploadEntry[] = [];

  private tempFileIds = [];

  get acceptedFileTypes(): string {
    if (
      !this.message?.responseRequest?.params?.format ||
      !Array.isArray(this.message.responseRequest.params.format)
    ) {
      return acceptedFiles.map((f) => f.value).join(',');
    }
    return this.message.responseRequest.params.format.join(',');
  }

  constructor(
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private api: FilesService,
    private auth: AuthService,
    private translator: TranslateService
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.value?.length) {
      for (let i = 0; i < this.value.length; i++) {
        let file: File | undefined;
        const fileData = parseFileReference(this.value[i]);
        if (typeof fileData !== 'string') {
          try {
            file = await this.getFile(fileData.id);
          } catch { }
        }
        if (file) {
          file = new File([file], (fileData as any)?.name ?? 'attachment', {
            type: file.type,
          });
          const newEntry: FileUploadEntry = { file };
          if (['image/jpeg', 'image/png'].includes(file.type)) {
            newEntry.preview = this.sanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(file)
            );
          }
          this.files.push(newEntry);
        } else {
          this.value.splice(i, 1);
          i++;
          this.hasError.emit(this.required);
        }
      }
    } else {
      if (this.value) {
        this.value.splice(0, this.value.length);
      }
      this.hasError.emit(this.required);
    }

    // note: when the user undo without saving the file, the file is still in the pending buffer
    // we need to re-upload the files, so the user can see the current state of the files uploaded
    this.reUploadPendingFiles();
  }

  private getFile(id: string): Promise<File> {
    return new Promise((resolve, reject) => {
      this.api
        .getFile({
          fileid: id,
        })
        .subscribe(
          (file: File) => {
            resolve(file);
          },
          (error) => {
            console.error(error);
            reject();
          }
        );
    });
  }

  onSelectFile(event: NgxDropzoneChangeEvent): void {
    if (
      this.files.length > 0 &&
      !this.message.responseRequest.params.multipleDocuments
    ) {
      this.snackBar.open('Es kann nur genau eine Datei ausgewählt werden.');
    } else {
      this.files.push(
        ...event.addedFiles.map((f) => {
          if (this.message.responseRequest.params.rename) {
            let newFileName;
            newFileName =
              this.message.responseRequest.params.documentNamePattern +
              f.name.match(/.([^.]*)$/)[0];
            let blob = f.slice(0, f.size, f.type);
            f = new File([blob], newFileName, {
              type: f.type,
            });
          }
          let preview: SafeResourceUrl | undefined;
          if (['image/jpeg', 'image/png'].includes(f.type)) {
            preview = this.sanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(f)
            );
          }
          return {
            file: f,
            preview,
          };
        })
      );
      if (event.addedFiles.length) {
        for (const fileId of this.tempFileIds) {
          if (!_.isNil(fileId)) {
            removePendingFile(fileId);
          }
        }
        this.tempFileIds = [];
        for (let i = 0; i < this.files.length; i++) {
          if (this.inline) {
            if (this.files.length) {
              if (!this.value) {
                this.value = [];
              }
              this.value.push(toFileReference(this.files[i].file));
              const newPendingId =
                this.value[i].match(/<<<PENDING::(.*)>>>/m)?.[1];
              if (!_.isNil(newPendingId)) {
                this.tempFileIds.push(newPendingId);
              }
              this.hasError.emit(false);
              this.answered.emit(this.value);
            }
          }
        }
      }
    }
    this.changed.emit();
    if (event.rejectedFiles.length) {
      this.snackBar.open(
        'Die Datei konnte nicht hochgeladen werden, da sie größer als 10MB ist.'
      );
      this.hasError.emit(true);
    }
  }

  onFileRemove(index: number): void {
    // save the file to be deleted before removing it from the files array
    const deletedFile = this.files[index];
    this.files.splice(index, 1);

    if (this.inline) {
      if (!_.isNil(this.tempFileIds[index])) {
        removePendingFile(this.tempFileIds[index]);
      }
      this.tempFileIds.splice(index, 1);
      this.value = [];
      this.answered.emit(undefined);
      this.hasError.emit(this.required);
    } else if (!this.auth.loggedIn) { // Q: should this be only if the user is not logged in?
      this.removeFileFromPendingBuffer(deletedFile.file);
      // remove the file from the value array and the tempFileIds array (if it exists in them!)
      this.tempFileIds.splice(index, 1);
      this.value.splice(index, 1);
    }

    this.changed.emit();
  }

  async saveFile(): Promise<void> {
    if (!this.files?.length) {
      return;
    }
    const path =
      this.message.responseRequest?.params?.targetFilePath ?? undefined;
    if (this.auth.loggedIn && this.stateId) {
      for (let i = 0; i < this.files.length; i++) {
        await uploadFile(
          this.files[i].file,
          this.stateId,
          {
            path: this.message?.responseRequest?.params?.targetFilePath ?? '',
            threadid: this.parser?.thread?.id,
          },
          this.api,
          []
        )
          .then((fileIdentifierObject) => {
            this.value.push(
              toFileReference(
                this.files[i].file,
                fileIdentifierObject.file_identifier,
                path,
                this.parser?.thread.id,
                this.parser?.visitor.currentNode._id
              )
            );
            this.hasError.emit(false);
          })
          .catch((error) => {
            if (error.file_identifier) {
              this.value.push(
                toFileReference(
                  this.files[i].file,
                  error.file_identifier,
                  path,
                  this.parser?.thread.id,
                  this.parser?.visitor.currentNode._id
                )
              );
              this.hasError.emit(false);
              this.answered.emit(this.value);
            } else {
              this.snackBar.open('Es gab ein Problem beim Upload der Datei.');
              this.hasError.emit(true);
            }
          });
      }
      this.answered.emit(this.value);
    } else {
      for (let i = 0; i < this.files.length; i++) {
        // if the file is already in the __pendingFileUploadBuffer, remove it
        // as it will be add again in the next step, when calling toFileReference
        this.removeFileFromPendingBuffer(this.files[i].file);

        this.value[i] = toFileReference(
          this.files[i].file,
          undefined,
          path,
          this.parser?.thread.id,
          this.parser?.visitor.currentNode._id
        );
      }
      this.hasError.emit(false);
      this.answered.emit(this.value);
    }
  }

  humanReadableFileFormats(formats: string[]) {
    const accumulatedTranslations = [];
    formats = _.flatMap(formats, (val) => val.split(/\s*,\s*/));
    for (const f of formats) {
      const targetTranslation = Object.entries(FORMAT_TRANSLATION_MAP).find(
        ([pattern, _]) => {
          const regex = new RegExp(`^${pattern}$`, 'm');
          if (f.match(regex)) return true;
        }
      )?.[1];
      if (
        !targetTranslation ||
        accumulatedTranslations.includes(targetTranslation)
      )
        continue;
      accumulatedTranslations.push(targetTranslation);
    }
    return accumulatedTranslations
      .map((tr) =>
        this.translator.instant(`client.process.fileUpload.format.${tr}`)
      )
      .join(', ');
  }

  reUploadPendingFiles() {
    // filter the pending files that belong to the current thread and node and path
    const pendingFiles = __pendingFileUploadBuffer.filter(
      (file) => (
        file && // make sure the file is not null
        file.thread === this.parser.thread.id &&
        file.path === this.message.responseRequest?.params?.targetFilePath &&
        file.nodeId === this.parser.visitor.currentNode._id
      )
    );

    if (pendingFiles.length === 0) return;

    // re-upload the files, using the same logic as if a user would upload them manually
    this.onSelectFile({
      addedFiles: pendingFiles.map((file) => file.file as File),
      rejectedFiles: [],
    } as NgxDropzoneChangeEvent);
  }

  removeFileFromPendingBuffer(file: File) {
    // find the file index in the pending buffer
    const pendingIndex = __pendingFileUploadBuffer.findIndex((pendingFile) => {
      // check for null values (Ex. [file, file, null, ...])
      if (!pendingFile) return false;

      // check: name, size, type, thread, path, nodeId
      const isSameFile = pendingFile.file.name === file.name &&
        (pendingFile.file as File).size === file.size &&
        (pendingFile.file as File).type === file.type &&
        pendingFile.thread === this.parser.thread.id &&
        pendingFile.path === this.message.responseRequest?.params?.targetFilePath &&
        pendingFile.nodeId === this.parser.visitor.currentNode._id;

      return isSameFile
    });

    // if the file is in the pending buffer, remove it)
    if (pendingIndex !== -1) {
      removePendingFile(pendingIndex);
    }
  }
}
