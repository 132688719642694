import { AbstractNode } from 'advoprocess/lib/nodes/abstract-node';
import { NodeProperties } from 'advoprocess/lib/types/nodeProperties';

export interface AvailableProperty {
  property: keyof NodeProperties;
  label: string;
  enableIf: (node: AbstractNode) => boolean;
}

export const allProperties: AvailableProperty[] = [
  {
    property: 'alwaysOverrideRefId',
    label: 'node.label.alwaysOverrideRefId',
    enableIf: (node: AbstractNode) => {
      return !!node?.refIds?.length;
    },
  },
  {
    property: 'richTextAsPopup',
    label: 'node.label.richTextAsPopup',
    enableIf: (node: AbstractNode) => {
      return ['VariableNode', 'EmailNode', 'OutputNode'].includes(
        node.identifier
      );
    },
  },
  {
    property: 'internalRefId',
    label: 'node.label.internalRefId',
    enableIf: (node: AbstractNode) => {
      return !!node?.refIds?.length;
    },
  },
  {
    property: 'sendViaMail',
    label: 'node.label.sendViaMail',
    enableIf: (node: AbstractNode) => {
      return ['QuestionNode', 'OutputNode'].includes(node.identifier);
    },
  },
];
