import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule,
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import * as Sentry from '@sentry/angular-ivy';
import { ApiModule } from '../api/api.module';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { WidgetsModule } from './widgets/widgets.module';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth/auth.service';
import { CustomCIService } from './services/customCI.service';
import { MatIconModule } from '@angular/material/icon';

import { AuthInterceptor } from './auth/auth.interceptor';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import {
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatLegacySnackBarModule as MatSnackBarModule,
} from '@angular/material/legacy-snack-bar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { RealmSelectionComponent } from './realm-selection.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { WebSocketService } from './common/websocket.service';
import { PipesModule } from './pipes/pipes.module';
import { SettingsService } from './common/settings.service';
import { LoginComponent } from './auth/login/login.component';
import { CommonSilberflussModule } from './common/common.module';
import { AdornmentsService } from './adornments.service';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PermissionsService } from './auth/permissions.service';
import { SilberflussErrorHandler } from './custom-error-handler';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    '.json?cb=' + new Date().getTime()
  );
}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        let langToSet = translate.getBrowserLang();
        translate.setDefaultLang('de');
        const urlCode = new URLSearchParams(location.search).get('lang');
        if (urlCode) {
          langToSet = urlCode;
        }
        if (!(langToSet === 'de' || langToSet === 'en')) {
          langToSet = 'de';
        }
        translate.use(langToSet).subscribe(
          () => {
            console.info(`Successfully initialized '${langToSet}' language.`);
          },
          () => {
            console.info(
              `Problem with '${langToSet}' language initialization.`
            );
            resolve(null);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}

@NgModule({
  declarations: [AppComponent, RealmSelectionComponent, LoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    ApiModule,
    HttpClientModule,
    NgScrollbarModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    WidgetsModule,
    PipesModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatMenuModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    CommonSilberflussModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      },
    },
    {
      provide: ErrorHandler,
      useExisting: SilberflussErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector, Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 4000 } },
    AuthService,
    PermissionsService,
    CustomCIService,
    WebSocketService,
    SettingsService,
    AdornmentsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
