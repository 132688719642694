import { Component, Inject, OnInit } from '@angular/core';
import { token } from './token';
import { SettingInputData } from '../node-setting.component';
import { HardCodedUser, ParsedAssignedUser, parseUserReference } from 'advoprocess';
import { isParseAssignedUser } from 'src/app/common/helpers';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-selection-input',
  template: `
    <mat-label>{{ data?.logicRef?.name | translate }}</mat-label>
    <app-participant-select-menu
      (selected)="setParticipant($event)"
      [alreadyAssigned]="currentlyAssigned ? [currentlyAssigned] : []"
    >
      <button mat-button>
        <div class="icon">
          <mat-icon *ngIf="!currentAssigneeIcon">question_mark</mat-icon>
          <mat-icon
            *ngIf="currentAssigneeIcon && !isLink(currentAssigneeIcon)"
            >{{ currentAssigneeIcon }}</mat-icon
          >
          <img
            *ngIf="currentAssigneeIcon && !!isLink(currentAssigneeIcon)"
            [src]="currentAssigneeIcon"
          />
        </div>
        <div class="details">
          <span *ngIf="!currentlyAssigned">{{ 'common.button.addParticipant' | translate}}</span>
          <strong *ngIf="!!currentlyAssigned">{{ currentAssigneeName }}</strong>
          <span *ngIf="currentAssigneeDetails">{{
            currentAssigneeDetails
          }}</span>
        </div>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </app-participant-select-menu>
  `,
  styles: [],
})
export class UserSelectionInput implements OnInit {
  currentlyAssigned: ParsedAssignedUser | HardCodedUser | undefined = undefined;

  constructor(
    @Inject(token) public data: SettingInputData,
    private translator: TranslateService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.data.logicRef?.value?.value) {
      const parsed = parseUserReference(this.data.logicRef?.value?.value);
      this.currentlyAssigned = parsed;
    }
  }

  setParticipant(event: string) {
    const parsed = parseUserReference(event);
    this.currentlyAssigned = parsed;
    this.data.logicRef.value.value = event;
  }

  isLink(icon: string) {
    return (
      (icon.startsWith('/') &&
        (icon.endsWith('png') || icon.endsWith('jpg'))) ||
      icon.startsWith('http')
    );
  }

  get currentAssigneeName(): string {
    if (!this.currentlyAssigned) return;
    if (isParseAssignedUser(this.currentlyAssigned)) {
      return this.currentlyAssigned.name ?? this.currentlyAssigned?.mail;
    } else {
      return this.translator.instant(
        `common.assigned.${this.currentlyAssigned.target}`
      );
    }
  }

  get currentAssigneeDetails(): string | undefined {
    if (!this.currentlyAssigned) return;
    if (isParseAssignedUser(this.currentlyAssigned)) {
      return this.currentlyAssigned.name
        ? this.currentlyAssigned?.mail
        : undefined;
    } else {
      return undefined;
    }
  }

  get currentAssigneeIcon(): string | undefined {
    if (!this.currentlyAssigned) return;
    if (!isParseAssignedUser(this.currentlyAssigned)) {
      return 'auto_awesome';
    }
    if (this.currentlyAssigned.source === 'group') {
      return 'groups';
    }
    return this.currentlyAssigned.profile_picture
      ? this.getProfilePicUrl(this.currentlyAssigned.profile_picture)
      : 'person';
  }

  getProfilePicUrl(profile_picture: string) {
    const realm =
      this.route.snapshot.paramMap.get('realm') ??
      this.route.firstChild?.snapshot.paramMap.get('realm');
    return environment.API_URL + '/file/' + profile_picture + '?realm=' + realm;
  }
}
