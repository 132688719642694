<h1 mat-dialog-title>
  {{ 'lawyer.dashboard.dossiers.new' | translate }}
</h1>

<mat-dialog-content>
  <form [formGroup]="createForm">
    <mat-error *ngIf="validation" [innerHTML]="validation | sanitizeHtml"></mat-error>
    <mat-form-field appearance="outline">
      <mat-label>{{ "lawyer.dashboard.dossiers.nameOfNew" | translate }}</mat-label>
      <input matInput formControlName="name" class="name-input" required (keyup)="updateValidation()" />
    </mat-form-field>
    <app-search-menu [entries]="queryProcesses.bind(this)" (entrySelected)="setProcess($event)" class="process-select"
      *ngIf="permissions.canRead('legal_processes') && commonService.checkFeatureFlagSync('processes.enabled')">
      <button mat-button [class.empty]="!createForm.get('fromProcess')?.value?.name"
        [disabled]="data?.lock?.includes('process')">
        <mat-label translate>{{ 'lawyer.dashboard.dossiers.fromProcess' }}</mat-label>
        <div class="content">
          <span class="name">{{
            createForm.get('fromProcess')?.value?.name ?? '' }}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </button>
    </app-search-menu>
    <div class="participants" [class.disabled]="data?.lock?.includes('participants')">
      <mat-label translate>{{ 'common.label.assigned' }}</mat-label>
      <app-dossier-assigned-input [participants]=" createForm.get('participants').value"
        (change)="updateParticipants($event)"></app-dossier-assigned-input>
      <button mat-button (click)="addSelfAsClerk()" class="self-as-clerk" *ngIf="!clerkAdded">
        <mat-icon>camera_front</mat-icon>
        <span>{{ 'lawyer.dashboard.dossiers.selfAsClerk' | translate }}</span>
      </button>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()">
    {{ 'common.button.abort' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="submit()"
    [disabled]="!createForm.get('name').value.length || !!validation">
    {{ 'lawyer.dashboard.dossiers.new' | translate }}
  </button>
</mat-dialog-actions>