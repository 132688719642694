<div class="title-bar">
  <div class="actions">
    <button mat-raised-button color="primary" (click)="saveDocument()" *ngIf="parsedDocument && canWrite">
      <mat-icon>save</mat-icon>
      <span translate class="hide-on-mobile">common.button.saveAndClose</span>
    </button><button mat-button (click)="dialogRef.close()">
      <span translate>common.button.close</span>
    </button>
  </div>
  <div class="file-info">
    <mat-icon *ngIf="parsedDocument">description</mat-icon>
    <mat-icon *ngIf="fileType === 'pdf'">picture_as_pdf</mat-icon>
    <mat-icon *ngIf="fileType === 'image'">image</mat-icon>
    <span class="file-name">{{data?.file?.name}}</span>
  </div>
</div>

<div class="content">
  <ng-container *ngIf="fileUrl && fileExists">
    <ng-container *ngIf="fileType === 'image'">
      <ng-container *ngTemplateOutlet="imageViewer"></ng-container>
    </ng-container>
    <ng-container *ngIf="fileType === 'pdf'">
      <pdf-viewer [src]="fileUrl['changingThisBreaksApplicationSecurity']" [render-text]="true" [zoom]="zoom"
        [rotation]="imageRotation"></pdf-viewer>
      <ng-container *ngTemplateOutlet="controls"></ng-container>
    </ng-container>
    <ng-container *ngIf="fileType === 'doc'">
      <div class="word-preview">
        <div class="actions">
          <div></div>
          <span><mat-icon>visibility</mat-icon>{{ 'process.files.wordDownloadHint' | translate }}</span>
          <button mat-button (click)="downloadFile()" color="primary">
            <mat-icon>download</mat-icon>
            {{ 'common.button.download' | translate}}
          </button>
        </div>
        <div #wordPreview class="word-wrapper"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="!fileType || fileType === 'other'">
      <div class="file-indicator">
        <mat-icon>file_copy</mat-icon>
        <h3>{{ data?.file?.name }}</h3>
        <span class="mime">{{ data?.file?.mime }}, {{ data?.file?.size ?? '' }}</span>
        <button mat-raised-button (click)="downloadFile()" color="primary">
          <mat-icon>download</mat-icon>
          {{ 'common.button.download' | translate}}
        </button>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="parsedDocument">
    <app-document [document]="parsedDocument" [readonly]="!canWrite" [zoom]="zoom" [dataStore]="documentDataStore">
      <div class="sidebar">
        <app-data-render-table [data]="formattedData" [highlightDataPoint]="highlightDocumentAnswer"
          *ngIf="formattedData"></app-data-render-table>
      </div>
    </app-document>
  </ng-container>
  <ng-container *ngIf="!fileExists">
    <div class="error-container">
      <div class="error-content">
        <mat-icon>plagiarism</mat-icon>
        <h3>{{ 'common.error.fileNotAvailable' | translate }}</h3>
        <p class="explanation">
          {{ 'common.error.fileMayBeDeleted' | translate }}
        </p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="error">
    <div class="error-container">
      <div class="error-content">
        <mat-icon>broken_image</mat-icon>
        <h3>{{ 'common.error.generic' | translate }}</h3>
        <p class="explanation">
          <ng-container *ngIf="error?.status === 403">{{ 'common.error.file.notAllowed' | translate }}</ng-container>
          <ng-container *ngIf="error?.status === 404">{{ 'common.error.file.notFound' | translate }}</ng-container>
          <ng-container *ngIf="error?.status === 500">{{ 'common.error.serverError' | translate }}</ng-container>
          <ng-container *ngIf="error?.status !== 500 && error?.status !== 404 && error?.status !== 403">{{
            'common.error.unknown' | translate }}</ng-container>
        </p>
        <p class="status-code" *ngIf="error?.status">
          <i>Error {{ error.status }}</i>
        </p>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #imageViewer>
  <div class="image-viewer">
    <div class="image-transform-outlet" (wheel)="wheelZoom($event)" (pointermove)="updateFocusInfo($event)"
      (pointerdown)="pointerDown($event)" (pointerup)="pointerUp($event)" (pointerleave)="pointerUp($event)"
      (pointercancel)="pointerUp($event)">
      <div class="image-container">
        <div class="image-wrapper"
          [style.transform]="'scale(' + zoom + ') translate(' + (imageOffset[0]) + 'px, ' + (imageOffset[1]) + 'px) rotate(' + imageRotation + 'deg) '">
          <img [src]="fileUrl" />
        </div>
      </div>
    </div>
    <div class="info">
      <span class="imageInfo">{{data?.file?.mime}}</span>
      <span class="positionInfo">{{crossHairPosition[0]}} x {{crossHairPosition[1]}}</span>
    </div>
    <ng-container *ngTemplateOutlet="controls"></ng-container>
  </div>
</ng-template>

<ng-template #controls>
  <div class="btn-controls">
    <button mat-icon-button (click)="downloadFile()">
      <mat-icon>download</mat-icon>
    </button>
    <button mat-icon-button (click)="rotateImage()">
      <mat-icon>rotate_right</mat-icon>
    </button>
    <button mat-icon-button (click)="zoomIn()">
      <mat-icon>zoom_in</mat-icon>
    </button>
    <button mat-icon-button (click)="zoomOut()">
      <mat-icon>zoom_out</mat-icon>
    </button>
  </div>
</ng-template>