import {
  Component,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProcessNode } from 'advoprocess';
import {
  AbstractNodeSetting,
  AbstractNodeSettingTypes,
} from 'advoprocess/lib/nodes/abstract-node';
import { AuthService } from 'src/app/auth/auth.service';
import {
  FormEditorComponent,
  FormEditorData,
} from '../../common/form-editor/form-editor.component';
import { NodeContext } from './node.component';
import _ from 'lodash';
import { ComponentType } from '@angular/cdk/portal';
import { MultiValueInputComponent } from './setting-inputs/multi-value-input.component';
import { FileListInputComponent } from './setting-inputs/file-list-input.component';
import { CheckboxInputComponent } from './setting-inputs/checkbox-input.component';
import { ListInputComponent } from './setting-inputs/list-input.component';
import { TextInputComponent } from './setting-inputs/text-input.component';
import { FileInputComponent } from './setting-inputs/file-input.component';
import { RichJsonInputComponent } from './setting-inputs/rich-json-input.component';
import { token } from './setting-inputs/token';
import { RichTextInputComponent } from './setting-inputs/rich-text-input.component';
import { MultiSettingInputComponent } from './setting-inputs/multi-setting-input.component';
import { FilterSettingInputComponent } from './setting-inputs/filter-setting-input';
import DataStore from 'advoprocess/lib/parser/data-store';
import { UserSelectionInput } from './setting-inputs/user-selection-input.component';

export interface SettingInputData {
  logicRef: AbstractNodeSetting;
  processNode: ProcessNode;
  allNodes: ProcessNode[];
  compact?: boolean;
  color: string;
  context: NodeContext;
  formControl: UntypedFormControl;
  labelSuffix?: string;
  dataStore?: DataStore;
}

@Component({
  selector: 'app-node-setting',
  templateUrl: './node-setting.component.html',
  styleUrls: ['./node-setting.component.scss'],
})
export class NodeSettingComponent implements OnInit {
  @Input() logicRef: AbstractNodeSetting;
  @Input() processNode: ProcessNode;
  @Input() allNodes: ProcessNode[];
  @Input() dataStore?: DataStore;
  @Input() compact: boolean = false;
  @Input() color: string;
  @Input() context: NodeContext;
  @Input() labelSuffix?: string;

  private modeComponents: {
    [key in AbstractNodeSettingTypes]?: ComponentType<any>;
  } = {
      'multi-value': MultiValueInputComponent,
      attachments: FileListInputComponent,
      checkbox: CheckboxInputComponent,
      commaSeparatedList: ListInputComponent,
      dropdown: TextInputComponent,
      file: FileInputComponent,
      number: TextInputComponent,
      rich_json: RichJsonInputComponent,
      rich_text: RichTextInputComponent,
      rich_string: RichTextInputComponent,
      string: TextInputComponent,
      setting_array: MultiSettingInputComponent,
      filters: FilterSettingInputComponent,
      userSelection: UserSelectionInput
    };

  public activeComponent: any;
  public activeComponentData: SettingInputData;
  public injector: Injector;

  @HostBinding('style.display')
  get hiddenStyle(): string | null {
    return !this.logicRef?.hideIf ||
      !this.logicRef.hideIf(this.processNode?.node?.config)
      ? null
      : 'none';
  }

  formControl = new UntypedFormControl('', [this.validatorFunction.bind(this)]);

  constructor(
    private matDialog: MatDialog,
    public auth: AuthService,
    private parentInjector: Injector
  ) { }

  ngOnInit(): void {
    this.formControl.setValue(this.logicRef?.value?.value ?? undefined);
    this.formControl.valueChanges.subscribe(() => {
      if (this.formControl.errors?.message) {
        return;
      }
      this.logicRef.value.value = this.formControl.value;
      this.processNode?.node?.onSettingChanged?.();
    });
    this.insertComponent();
  }

  async insertComponent() {
    const targetComponent = this.modeComponents[this.logicRef.type];
    this.activeComponent = null;
    setTimeout(() => {
      if (!targetComponent) return;
      this.activeComponent = targetComponent;
      this.activeComponentData = {
        logicRef: this.logicRef,
        allNodes: this.allNodes,
        color: this.color,
        context: this.context,
        formControl: this.formControl,
        processNode: this.processNode,
        compact: this.compact,
        labelSuffix: this.labelSuffix,
        dataStore: this.dataStore ?? undefined,
      };
      this.createInjector();
    }, 150);
  }

  createInjector() {
    this.injector = Injector.create({
      providers: [{ provide: token, useValue: this.activeComponentData }],
      parent: this.parentInjector,
    });
  }

  private validatorFunction(
    control: UntypedFormControl
  ): null | { message: string } {
    if (this.logicRef?.validator) {
      return this.logicRef.validator(control.value || '');
    } else {
      return null;
    }
  }

  editForm(): void {
    this.matDialog.open<FormEditorComponent, FormEditorData>(
      FormEditorComponent,
      {
        width: '90%',
        height: '90%',
        maxWidth: '90vw',
        maxHeight: '90vh',
        panelClass: 'form-editor-container',
        disableClose: true,
        data: {
          questions: this.logicRef.value.value,
          onUpdateQuestions: (questions) => {
            this.logicRef.value.value = questions;
          },
          name: this.processNode.node?.config?.name?.value,
          dataSource: this.allNodes,
        },
      }
    );
  }

  @HostListener('keydown', ['$event'])
  preventPropagation(event) {
    event.stopPropagation();
  }
}
