import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs';
import { DestroyNotifier } from 'src/app/common/destroy-notifier';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends DestroyNotifier {
  constructor(
    private auth: AuthService,
    private activeRoute: ActivatedRoute,
    router: Router
  ) {
    super();
    this.auth.ready$
      .pipe(
        filter((t) => t),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.auth.loggedIn) {
          setTimeout(() => {
            navigateAfterLogin(router, auth);
          }, 500);
        } else {
          this.auth.logIn();
        }
      });
  }
}

export function navigateAfterLogin(router: Router, auth: AuthService) {
  const realm = auth.rawDecoded.realm ?? '';
  const urlParams = new URLSearchParams(window.location.search);
  let redirectionTarget = urlParams.get('redirect');
  if (redirectionTarget && redirectionTarget !== `%2F${realm}`) {
    redirectionTarget = decodeURIComponent(redirectionTarget);
    console.log('redirecting to', redirectionTarget, '...');
    router.navigateByUrl(redirectionTarget);
  } else {
    router.navigateByUrl(
      !realm ? '/' : `/${realm}/${auth.isClient ? '' : 'intern/overview'}`
    );
  }
}
