import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RichTextEditorComponent } from 'src/app/widgets/rich-text-editor/rich-text-editor.component';
import { token } from './token';
import { SettingInputData } from '../node-setting.component';

@Component({
  selector: 'app-rich-text-input',
  template: `
    <div
      class="rich-text-edit"
      [class.compact]="data.logicRef.type === 'rich_string'"
      (pointerdown)="$event.stopPropagation()"
    >
      <div class="rich-label" (click)="onEdit()">
        {{ data.logicRef.name | translate }}{{ data.labelSuffix ?? '' }}
      </div>
      <ng-container
        *ngIf="
          !data.logicRef.popup &&
          !data?.processNode?.node?.properties?.richTextAsPopup
        "
      >
        <div
          [innerHTML]="data.formControl.value | sanitizeHtml"
          (pointerdown)="onEdit()"
          (focus)="onEdit()"
          contenteditable
          *ngIf="!editMode"
          style="width: 484px; padding: 9px; outline: 0"
          tabindex="0"
        ></div>
        <app-rich-text-editor
          [readonly]="false"
          [text]="data.formControl.value"
          (textChange)="data.formControl.setValue($event)"
          [dataSource]="data.allNodes"
          [dataStore]="data.dataStore"
          [ownNode]="data.processNode"
          [buttons]="
            'font,fontsize,brush,|,bold,italic,underline,strikethrough,|,ul,ol,|,undo,redo'
          "
          [stringInput]="data.logicRef.type === 'rich_string'"
          [inline]="true"
          (pointerdown)="onEdit()"
          *ngIf="editMode"
        >
        </app-rich-text-editor>
      </ng-container>
      <ng-container
        *ngIf="
          data.logicRef.popup ||
          data?.processNode?.node?.properties?.richTextAsPopup
        "
      >
        <button mat-raised-button color="primary" (click)="richTextEditPopup()">
          {{
            'common.button.editWithName'
              | translate: { name: (data.logicRef.name | translate) }
          }}
        </button>
      </ng-container>
    </div>
  `,
  styles: [],
})
export class RichTextInputComponent {
  get editMode() {
    return (
      this.data.context?.currentEditorLocation === this.data.processNode?._id ??
      false
    );
  }

  constructor(
    private matDialog: MatDialog,
    @Inject(token) public data: SettingInputData,
    private el: ElementRef
  ) {}

  richTextEditPopup() {
    const dialogRef = this.matDialog.open(RichTextEditorComponent, {
      height: '800px',
    });
    const editor = dialogRef.componentInstance;
    editor.text = this.data.formControl.value;
    editor.readonly = false;
    editor.textChange.subscribe((event) => {
      this.data.formControl.setValue(event);
    });
    editor.dataSource = this.data.allNodes;
    editor.ownNode = this.data.processNode;
  }

  onEdit() {
    if (!this.data.context) {
      return;
    }
    this.data.context.currentEditorLocation = this.data.processNode._id;
    setTimeout(() => {
      this.el?.nativeElement?.querySelector('.jodit-wysiwyg')?.focus();
    }, 100);
  }
}
