import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { addressFields, ChatMessage } from 'advoprocess';

@Component({
  selector: 'app-multitext-input',
  template: `
    <div class="answer-title" *ngIf="inline && message?.content">
      <mat-icon>info</mat-icon>
      <p [innerHTML]="message.content | sanitizeHtml"></p>
    </div>
    <div
      class="multitext-input"
      [formGroup]="addressControls"
      *ngIf="addressControls"
    >
      <ng-container
        *ngFor="let field of message.responseRequest.params.possibleValues"
      >
        <mat-form-field appearance="fill" [attr.type]="field">
          <mat-label>{{ addressField(field).title | translate }}</mat-label>
          <input
            [placeholder]="addressField(field).title | translate"
            [type]="addressField(field).type"
            [attr.inputmode]="getInputMode(field)"
            [name]="field"
            matInput
            [formControlName]="field"
          />
        </mat-form-field>
        <span
          class="error-message"
          *ngIf="
            addressControls.get(field).errors &&
            addressControls.get(field).value?.length > 0
          "
          >{{ addressControls.get(field).errors.message | translate }}</span
        >
      </ng-container>
    </div>
    <button
      mat-raised-button
      color="primary"
      (click)="answered.emit(value)"
      [disabled]="!addressValid"
      *ngIf="!inline"
    >
      {{ 'common.button.continue' | translate }}
    </button>
  `,
})
export class MultitextInputComponent implements OnInit {
  @Input() message: ChatMessage;
  @Input() initialValue: any;
  @Input() required: boolean = true;
  @Input() value: any;
  @Input() inline: boolean;
  @Output() valueChanged = new EventEmitter<any>();

  @Output() answered = new EventEmitter<any>();
  @Output() hasError = new EventEmitter<boolean>();

  addressControls: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
    this.addressControls = new UntypedFormGroup(
      this.message.responseRequest.params.possibleValues.reduce(
        (acc, field) => {
          acc[field] = new UntypedFormControl(
            '',
            this.required ? [Validators.required] : []
          );
          const dsrc = addressFields.find((f) => f.value === field);
          if (dsrc?.validator) {
            acc[field].addValidators([
              () => {
                if (this.message.responseRequest.params.canBeSkipped) {
                  if (acc[field].value === '' || acc[field].value === null) {
                    acc[field].value = null;
                    return null;
                  } else {
                    return dsrc.validator(acc[field].value || '');
                  }
                } else {
                  return dsrc.validator(acc[field].value || '');
                }
              },
            ]);
          }
          if (this.initialValue && this.initialValue[field]) {
            acc[field].setValue(this.initialValue[field]);
          }
          acc[field].valueChanges.subscribe(() => {
            this.value =
              this.message.responseRequest.params.possibleValues.reduce(
                (r, fieldName) => {
                  r[fieldName] = this.addressControls.get(fieldName).value;
                  return r;
                },
                {}
              );
            if (this.inline) {
              this.answered.emit(this.value);
              this.hasError.emit(!this.addressValid);
            }
            this.valueChanged.emit(this.value);
          });
          return acc;
        },
        {}
      )
    );
    this.hasError.emit(!this.addressValid);
  }

  get addressValid(): boolean {
    return (
      (!this.required || this.value) &&
      Object.keys(this.addressControls.controls).every((key) => {
        return (
          key === 'add_info' ||
          !this.required ||
          (this.addressControls.get(key).value &&
            this.addressControls.get(key).value.length > 0)
        );
      }) &&
      !this.addressControls.errors
    );
  }

  addressField(val: string): any {
    return addressFields.find((field) => field.value === val);
  }

  getInputMode(field: string): string | null {
    switch (field) {
      case 'zip_code':
        return 'numeric';
      default:
        return null;
    }
  }
}
