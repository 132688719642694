import { Injectable } from '@angular/core';
import { WebSocketMessage } from 'advoprocess';
import { BehaviorSubject, Subject, fromEvent, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private _socket: WebSocket | undefined;

  private _entity: 'client' | 'lawyer' | undefined;
  private _uuid: string | undefined;

  public messages$ = new Subject<{ [key: string]: any }>();

  public socket$ = new BehaviorSubject<WebSocket | undefined>(undefined);

  constructor() {}

  public connect(entity: 'client' | 'lawyer', uuid: string) {
    this._socket = new WebSocket(environment.WEBSOCKET_URL ?? '');

    this._entity = entity;
    this._uuid = uuid;

    this._socket.addEventListener('open', () => {
      console.log('Socket open!');
      this.socket$.next(this._socket);
      this.send({
        operation: 'register',
        payload: {
          entity,
          uuid,
        },
      });
    });

    fromEvent(this._socket, 'open')
      .pipe(
        switchMap(() => {
          return timer(5000, 5000);
        }),
        takeUntil(fromEvent(this._socket, 'close'))
      )
      .subscribe(() => {
        this.send({
          operation: 'ping',
        });
      });

    fromEvent(this._socket, 'message')
      .pipe(
        filter((m: any) => m?.data && m.data !== 'pong'),
        map((result: any) => {
          return JSON.parse(result.data);
        })
      )
      .subscribe((msg) => {
        this.messages$.next(msg);
      });

    this.messages$.pipe(filter((m) => !!m.error)).subscribe(() => {
      this.reconnect();
    });

    this._socket.addEventListener('close', this.onClose.bind(this));

    this._socket.addEventListener('error', (e: any) => {
      console.error('Socket encountered error: ', e.message, 'Closing socket');
      this._socket.close();
    });
  }

  private onClose(e) {
    console.log(
      'Socket is closed. Reconnect will be attempted in 1 second.',
      e.reason
    );
    setTimeout(() => {
      if (!this._entity || !this._uuid) {
        return;
      }
      this.connect(this._entity, this._uuid);
    }, 1000);
  }

  public reconnect() {
    if (!this._socket || this._socket.CLOSED) return;
    this._socket.close();
  }

  public disconnect() {
    this._entity = this._uuid = undefined;
    if (this._socket) {
      this._socket.removeEventListener('close', this.onClose);
      this._socket.close();
    }
  }

  send(message: WebSocketMessage) {
    this._socket.send(JSON.stringify(message));
  }
}
