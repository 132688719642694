<div class="main-content">
  <div class="left">
    <div class="actions">
      <button mat-button color="primary" (click)="back()">
        <mat-icon>chevron_left</mat-icon>
        <span>{{ "common.button.back" | translate }}</span>
      </button>
    </div>
    <ng-scrollbar>
      <div class="user-info">
        <div class="user-icon">
          <span *ngIf="user.entity !== 'role' && !corporateIdentity?.lawyer_headshot">{{ initials }}</span>
          <img *ngIf="user.entity !== 'role' && !!corporateIdentity?.lawyer_headshot" [src]="API_URL +
              '/file/' +
              corporateIdentity?.lawyer_headshot +
              '?realm=' +
              realm" />
          <mat-icon *ngIf="user.entity === 'role'">
            {{ userInfoForm.get("icon")?.value }}
          </mat-icon>
          <button class="edit-usr-icon" (click)="editUserIcon()" *ngIf="user.entity !== 'client' && canWriteUser">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
        <div>
          <span class="name" *ngIf="fullName">
            {{ fullName }}
          </span>
          <span class="email" *ngIf="userInfoForm?.get('mail')?.value?.length">
            {{ userInfoForm?.get("mail")?.value }}
          </span>
        </div>
      </div>
      <div class="section btns">
        <a mat-icon-button *ngIf="
            userInfoForm?.get('mail')?.value?.length &&
            userInfoForm?.get('uuid')?.value !== auth.userId
          " [href]="'mailto:' + userInfoForm?.get('mail')?.value">
          <mat-icon>email</mat-icon>
        </a>
      </div>
      <div class="section">
        <h3>{{ "common.label.contactData" | translate }}</h3>
        <mat-form-field appearance="outline">
          <mat-label>{{ "common.label.userId" | translate }}</mat-label>
          <input matInput [formControl]="userInfoForm.get('uuid')" />
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="user.entity !== 'role'">
          <mat-label>{{ "common.label.mail" | translate }}</mat-label>
          <input matInput [formControl]="userInfoForm.get('mail')" />
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="user.entity === 'role'">
          <mat-label>{{ "common.label.name" | translate }}</mat-label>
          <input matInput [formControl]="userInfoForm.get('roleName')" />
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="user.entity !== 'role'">
          <mat-label>{{ "common.label.firstName" | translate }}</mat-label>
          <input matInput [formControl]="userInfoForm.get('firstName')" />
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="user.entity !== 'role'">
          <mat-label>{{ "common.label.lastName" | translate }}</mat-label>
          <input matInput [formControl]="userInfoForm.get('lastName')" />
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="user.entity === 'role'">
          <mat-label>{{ "common.label.description" | translate }}</mat-label>
          <input matInput [formControl]="userInfoForm.get('description')" />
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="user.entity === 'client'">
          <mat-label>{{ "profile.language" | translate }}</mat-label>
          <mat-select [formControl]="userInfoForm.get('preferred_locale')">
            <mat-option value="de">Deutsch</mat-option>
            <mat-option value="en">English</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="
            userInfoForm.get('createdAt')?.value?.length &&
            user.entity !== 'role'
          ">
          <mat-label>{{ "process.files.createdAt" | translate }}</mat-label>
          <input matInput [value]="
              userInfoForm.get('createdAt').value | date: 'dd.MM.yyyy HH:mm'
            " disabled />
        </mat-form-field>
        <ng-container *ngIf="canWriteUser || isSelf">
          <button mat-raised-button color="primary" style="margin-bottom: 10px" (click)="changePassword()"
            *ngIf="user.entity === 'client' && userInfoForm.get('canAuth').value">
            <mat-icon style="margin-right: 10px">key</mat-icon>
            <span *ngIf="!userInfoForm.get('guest').value">{{ "lawyer.userSettings.changePassword" | translate }}</span>
            <span *ngIf="userInfoForm.get('guest').value">{{ "lawyer.userSettings.setPassword" | translate }}</span>
          </button>
          <button mat-raised-button color="primary" style="margin-bottom: 10px" (click)="createSystemAccess()"
            *ngIf="user.entity === 'client' && !isClient && !userInfoForm.get('canAuth').value && clientPortalEnabled">
            <mat-icon style="margin-right: 10px">key</mat-icon>
            <span>{{ "lawyer.userSettings.requestLogin" | translate }}</span>
          </button>
          <button mat-button color="primary" (click)="requestPasswordReset()" class="btn-overflow-ellipsis"
            *ngIf="!isClient && (user.entity === 'lawyer' || (user.entity === 'client' && userInfoForm.get('canAuth').value))">
            <mat-icon style="margin-right: 10px">mail</mat-icon>
            <span *ngIf="!userInfoForm.get('guest').value"
              [title]="'lawyer.userSettings.sendPasswordReset' | translate">
              {{ "lawyer.userSettings.sendPasswordReset" | translate}}
            </span>
            <span *ngIf="userInfoForm.get('guest').value" [title]="'lawyer.userSettings.requestLogin' | translate">
              {{ "lawyer.userSettings.requestLogin" | translate }}
            </span>
          </button>
        </ng-container>
      </div>
      <div class="section" *ngIf="canWriteUser && !!corporateIdentity && user.entity === 'lawyer'">
        <mat-form-field appearance="outline">
          <mat-label>{{ "lawyer.userSettings.contactPhone" | translate }}</mat-label>
          <input matInput [value]="corporateIdentity.contact_phone ?? ''"
            (change)="updateCI($event.target.value, 'contact_phone')" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "lawyer.userSettings.contactFax" | translate }}</mat-label>
          <input matInput [value]="corporateIdentity.contact_fax ?? ''"
            (change)="updateCI($event.target.value, 'contact_fax')" />
        </mat-form-field>
        <div class="rich-setting">
          <span class="label">{{ 'lawyer.userSettings.address' | translate }}</span>
          <app-rich-setting-editor (valueChanged)="updateCI($event, 'address')"
            buttons="bold, italic, underline,|, ul, ol,|, paragraph, hr, link"
            [value]="corporateIdentity.address ?? ''"></app-rich-setting-editor>
        </div>
        <div class="rich-setting">
          <span class="label">{{ 'lawyer.userSettings.furtherInfos' | translate }}</span>
          <app-rich-setting-editor (valueChanged)="updateCI($event, 'further_infos')"
            buttons="bold, italic, underline,|, ul, ol,|, paragraph, hr, link"
            [value]="corporateIdentity.further_infos ?? ''"></app-rich-setting-editor>
        </div>
      </div>
    </ng-scrollbar>
  </div>
  <div class="right">
    <div class="tabs">
      <button class="tab" (click)="activeTab = 'data'" [class.active]="activeTab === 'data'"
        *ngIf="user.entity !== 'role'">
        {{ "common.label.clientVariables" | translate }}
      </button>
      <button class="tab" (click)="activeTab = 'dossiers'" [class.active]="activeTab === 'dossiers'"
        *ngIf="user.entity !== 'role' && !isClient && canRead('execution_states')">
        {{ "common.label.states" | translate }}
      </button>
      <button class="tab" (click)="activeTab = 'members'" [class.active]="activeTab === 'members'"
        *ngIf="commonService.checkFeatureFlagSync('groups.enabled')">
        {{ (user.entity === 'role' ? "common.label.members" : "lawyer.dashboard.clients.groups") | translate }}
      </button>
      <button class="tab" (click)="activeTab = 'permissions'" [class.active]="activeTab === 'permissions'"
        *ngIf="!isClient && user.entity !== 'client' && !isSelf && canWriteUser && commonService.checkFeatureFlagSync('lawyers.permissions')">
        {{ "common.label.permissions" | translate }}
      </button>
    </div>
    <div class="tab-content">
      <ng-scrollbar>
        <div class="tab" *ngIf="activeTab === 'data'">
          <app-data-render-table [data]="formattedDataAnswers" (fieldChange)="fieldChange($event)" *ngIf="!isClient"
            [validator]="validateVariables.bind(this)" (afterValidate)="variableValidation = $event"
            [getOptionsFor]="getVariableOptions.bind(this)" label="lawyer-variables"></app-data-render-table>
          <app-data-render-table [data]="formattedDataAnswers" *ngIf="isClient"
            label="client-variables"></app-data-render-table>
        </div>
        <div class="tab" *ngIf="activeTab === 'dossiers'">
          <app-table [config]="dossierTableConfig" *ngIf="dossierTableConfig"></app-table>
        </div>
        <div class="tab" *ngIf="activeTab === 'members'">
          <div class="sketch-actions" *ngIf="membersTableConfig">
            <app-participant-select-menu [alreadyAssigned]="[]" [types]="['lawyers', 'clients']"
              (selected)="addMember($event)">
              <button mat-button>
                <mat-icon class="button-icon">add</mat-icon>
                <span>{{
                  "lawyer.dashboard.groups.newMember" | translate
                  }}</span>
              </button>
            </app-participant-select-menu>
          </div>
          <app-table [config]="membersTableConfig" *ngIf="membersTableConfig" #membersTable></app-table>
          <app-table [config]="rolesTableConfig" *ngIf="rolesTableConfig" #rolesTable></app-table>
        </div>
        <div class="tab" *ngIf="activeTab === 'permissions' && !isSelf">
          <app-permission-edit [userId]="user?.uuid" [entity]="user?.entity" *ngIf="user"
            [testMode]="getTestModel()"></app-permission-edit>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>
<div class="bottom">
  <button mat-raised-button color="warn" (click)="deleteUser()" *ngIf="!isClient">
    <mat-icon>delete</mat-icon>
    <span>{{ "common.button.delete" | translate }}</span>
  </button>
  <div class="spacer"></div>
  <button mat-raised-button color="primary" [disabled]="
      !userInfoForm.valid || (!somethingChanged && userInfoForm.pristine) || variableValidation !== true
    " (click)="save()">
    <mat-icon>save</mat-icon>
    <span>{{ "common.button.save" | translate }}</span>
  </button>
</div>